import type { CreateAccountSocialFlowStep, FirebaseProviderId } from "./types";

export const baseTKey = 'create_account';
export const ANALYTICS = 'firebase_auth'

export const PROVIDER_ID_LOOKUP: Record<CreateAccountSocialFlowStep, FirebaseProviderId> = {
  'apple_id': 'apple.com',
  'google': 'google.com'
}

export const PROVIDER_NAME_LOOKUP: Record<FirebaseProviderId, CreateAccountSocialFlowStep> = {
  'apple.com': 'apple_id',
  'google.com': 'google'
}
