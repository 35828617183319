
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FaceReadingState } from './types'

const initialState: FaceReadingState = {
  faceImg: '',
  faceImgWithKeyPoints: null,
  faceImgDims: { width: 0, height: 0, isFullScreen: false },
  faceKeyPoints: null,
  faceLinesPercent: null,
}

export const faceReadingSlice = createSlice({
  name: 'faceReading',
  initialState,
  reducers: {
    setFaceImgWithKeyPoints: (state, action: PayloadAction<string | null>) => ({
      ...state,
      faceImgWithKeyPoints: action.payload,
    }),
    setFaceLinesPercent: (state, action: PayloadAction<number[] | null>) => ({
      ...state,
      faceLinesPercent: action.payload,
    }),
    setFaceData: (state, action: PayloadAction<Partial<FaceReadingState>>) => ({
      ...state,
      faceImg: action.payload.faceImg ? action.payload.faceImg : state.faceImg,
      faceImgDims: action.payload.faceImgDims ? action.payload.faceImgDims : state.faceImgDims,
      faceKeyPoints: action.payload.faceKeyPoints ? action.payload.faceKeyPoints : state.faceKeyPoints,
    }),
  },
})

export const { setFaceData } = faceReadingSlice.actions;