import React, { useEffect } from 'react';

const URL = 'https://payment-widget.asapcashier-dev.com';

export const CardElement: React.FC<any> = ({ onSubmit, onSuccess, onError, }) => {
  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event?.origin === URL) {
        switch (event.data?.type) {
          // case 'PROCEEDING_LOADING':
          //   onSubmit();
          //   break;
          case 'PROCEEDING_SUCCESS':
            onSuccess();
            break;
          case 'PROCEEDING_ERROR':
            onError();
            break;
        }
      }
    }

    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    }
  }, [onSubmit, onSuccess, onError])

  return (
    <iframe
      src={URL}
      title="payment"
      allowTransparency={true}
      style={{ width: '100%', height: '450px', border: 'none' }}
    />
  );
}
