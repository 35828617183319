import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import {
  getAuth
} from 'firebase/auth';

import DeviceProps from '@magnus/react-native-device-props';

import Analytics from '@web-solutions/module-analytics';

import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';

//@ts-ignore
import { authFirebase } from 'src/api/auth';

import { setAlreadyCreatedAccount } from '../../store/actions';

import { ANALYTICS } from '../constants';

import { mapProviderName } from '../utils';

export const useCheckProviderExists = ({ linkAuthResult, onNextScreen }: { linkAuthResult: any, onNextScreen: () => void }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!linkAuthResult) {
      const checkProviders = async () => {
        let auth = getAuth()

        if (!auth.currentUser && authFirebase) {
          const idfm = await DeviceProps.getIDFM()

          await authFirebase(idfm)

          auth = getAuth()
        }
        console.log({ auth }, 'checkProviders')
        if (auth?.currentUser?.providerData?.[0]) {
          Analytics.trackEvent(ANALYTICS, 'provider_exists', { provider: mapProviderName(auth?.currentUser?.providerData?.[0]?.providerId) })

          stringifyUrlParams({ alreadyCreatedAccount: null });
          dispatch(setAlreadyCreatedAccount(true));
          onNextScreen()
        }
      }

      checkProviders()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkAuthResult])
}