import { type BaseSyntheticEvent, type ReactNode, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { AuthErrorCodes } from "firebase/auth";

import { t, TM } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Progress } from '@web-solutions/base-app/components/upsales';
import { Title, Text, Preloader } from '@web-solutions/core/ui-elements';
import ErrorPopUp from '@web-solutions/core/containers/error-popup';

import type { ImageSource } from '@web-solutions/core/interfaces/images';
import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';

import { useSetKeyboardInsetHeightVar } from '@web-solutions/core/hooks/use-set-keyboard-inset-height-var';

import { setAlreadyCreatedAccount } from '../store/actions';
import { selectLinkAuthResult } from '../store/selectors';

import { signUpWithSocials, signUpWithEmail } from '../api/auth';

import SuccessPopup from '../components/success-popup';

import { baseTKey, ANALYTICS, PROVIDER_ID_LOOKUP } from './constants';

import {
  Divider,
  EmailForm,
  AppleIdButton,
  GoogleButton
} from './components';

import { useCreateAccount, useMapCreateAccountFlow } from './hooks';

import type { CreateAccountFlowStep, CreateAccountSocialFlowStep } from './types';

import classes from './style.module.scss';

interface Props {
  createAccountFlowConfig: CreateAccountFlowStep[];
  email: string;
  successPopupContent: {
    title: string,
    button_text: string,
    image: ImageSource,
  },
  onNextScreen: () => void;
  title?: string;
  stepsText?: any;
  withInputLabels?: boolean;
  withLetterIcon?: boolean;
  className?: string;
  progressComponent?: ReactNode,
}

const ModuleCreateAccount: React.FC<Props> = ({
  className,
  createAccountFlowConfig,
  title,
  stepsText,
  email,
  progressComponent,
  successPopupContent,
  withInputLabels = true,
  withLetterIcon,
  onNextScreen,
}) => {
  const createAccountFlow = useMapCreateAccountFlow(createAccountFlowConfig);

  const linkAuthResult = useSelector(selectLinkAuthResult);

  const dispatch = useDispatch();

  const {
    data,
    validation,
    erorPopup,
    successPopup,
    setSuccessPopup,
    setErorPopup,
    handleChange,
  } = useCreateAccount({ email, linkAuthResult, onNextScreen });

  useSetKeyboardInsetHeightVar();

  const [isLoading, setIsLoading] = useState(false);

  const isEmailOnly = createAccountFlow.length === 1 && createAccountFlow[0] === 'email';

  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        setIsLoading(false)
        //fixes loading when returning from integration page by clicking on back button in browser 
      }
    };

    window.addEventListener("pageshow", handlePageShow);
    return () => window.removeEventListener("pageshow", handlePageShow);
  }, []);

  const handleErrorPopupClose = () => {
    setErorPopup({ show: false, provider: '' });
  };

  const handleSkipClick = () => {
    Analytics.trackEvent(`${ANALYTICS}_error_popup`, 'try_later', { provider: erorPopup.provider });

    setErorPopup({ show: false, provider: '' });

    onNextScreen();
  };

  const handleEmailFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUBMIT, { provider: 'email' });

    setIsLoading(true)

    try {
      await signUpWithEmail(data?.email, data?.password);

      Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUCCESS, { provider: 'email' });

      dispatch(setAlreadyCreatedAccount(true));
      stringifyUrlParams({ alreadyCreatedAccount: null });
      setSuccessPopup({ show: true, provider: 'email' });
    } catch (error: any) {
      Analytics.trackEvent(ANALYTICS, EVENT_ACTION.ERROR, { provider: 'email', error: error?.message });

      setErorPopup({ show: true, provider: error?.code === AuthErrorCodes.EMAIL_EXISTS ? 'email_used' : 'email' });
    } finally {
      setIsLoading(false)
    }
  }

  const handleSocialClick = (flow: CreateAccountSocialFlowStep) => async (e: BaseSyntheticEvent) => {
    e.preventDefault()

    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUBMIT, { provider: flow });

    setIsLoading(true)

    try {
      await signUpWithSocials(PROVIDER_ID_LOOKUP[flow])
    } catch (error: any) {
      Analytics.trackEvent(ANALYTICS, EVENT_ACTION.ERROR, { provider: flow, error: error?.message });

      setErorPopup({ show: true, provider: flow });
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseSuccessPopup = () => {
    Analytics.trackEvent(`${ANALYTICS}_success_popup`, EVENT_ACTION.CLOSE, { provider: successPopup.provider });

    setSuccessPopup({ show: false, provider: '' });

    onNextScreen();
  }

  const renderFlow = createAccountFlow.map((step, index) => {
    switch (step) {
      case 'email': {
        return (
          <EmailForm
            key={step}
            data={data}
            validation={validation}
            withInputLabels={withInputLabels}
            withLetterIcon={withLetterIcon}
            isEmailOnly={isEmailOnly}
            isLoading={isLoading}
            onSubmit={handleEmailFormSubmit}
            onInputChange={handleChange}
          />
        )
      }
      case 'apple_id': {
        return (
          <AppleIdButton
            key={step}
            disabled={isLoading}
            onClick={handleSocialClick('apple_id')}
          />
        )
      }
      case 'google': {
        return (
          <GoogleButton
            key={step}
            disabled={isLoading}
            onClick={handleSocialClick('google')}
          />
        )
      }
      case 'divider': {
        return (
          <Divider key={index} />
        )
      }
      default: {
        return null
      }
    }
  });

  return (
    <>
      {isLoading && <Preloader />}
      <div className={classNames(classes.account_wrap, className)}>
        {progressComponent ?
          progressComponent :
          <Progress currentStep='account' stepsText={stepsText} />
        }
        <Title level='h2' className={classes.title}>
          <TM k={title} defaultKey={`${baseTKey}.title`} />
        </Title>
        <Text className={classes.subtitle}>
          {t(`${baseTKey}.subtitle`)}
        </Text>
        <div className={classes.flowWrapper}>
          {renderFlow}
        </div>
      </div>
      <ErrorPopUp
        visible={erorPopup.show}
        title={t(`${baseTKey}.error_popup.title`)}
        subTitle={t(`${baseTKey}.error_popup.message.${erorPopup.provider}`, { defaultValue: '' })}
        btnTitle={t(`${baseTKey}.error_popup.btn`)}
        skipTitle={t(`${baseTKey}.error_popup.skip`)}
        onClose={handleErrorPopupClose}
        onSkipClick={handleSkipClick}
      />
      <SuccessPopup
        category={ANALYTICS}
        isOpen={successPopup.show}
        image={successPopupContent.image}
        title={successPopupContent.title}
        text={t(`${baseTKey}.success_popup.message.${successPopup.provider}`, { defaultValue: '' })}
        button_text={successPopupContent.button_text}
        onSubmit={handleCloseSuccessPopup}
      />
    </>
  )
}
export default ModuleCreateAccount;