import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { CardElement as CardElementStripe } from '@stripe/react-stripe-js';

import { CardElement as CardElementSolidgate } from '../../solidgate';
import { CardElement as CardElementPaddle } from '../../paddle';
import { CardElement as CardElementTruegate } from '../../truegate';

import { PaymentSystem } from '../../constants';
import { getPublicKeys } from '../../utils/storage';

import { CardElement as CardElementRecurly } from './recurly';

const CardElement = React.forwardRef(
  (
    {
      paymentSystem,
      product,
      options,
      className,
      onSubmit,
      onChange,
      onReady,
      onSuccess,
      onError,
      onApplePayClick,
      onApplePaySubmit,
      onGooglePayClick,
      onGooglePaySubmit,
      onUserInfoChange,
      onEvent,
      formStyles,
      inputStyles,
      labelStyles,
      submitStyles,
      notEmptyLabelStyles,
      onCheckPrepaid,
    },
    ref
  ) => {
    const publicKeys = getPublicKeys();

    const handleSubmit = useCallback(
      (formData) => {
        formData.method = 'card';
        onSubmit(formData);
      },
      [onSubmit]
    );

    switch (paymentSystem) {
      case PaymentSystem.RECURLY:
        return (
          <CardElementRecurly
            ref={ref}
            product={product}
            options={options}
            className={className}
            onSubmit={handleSubmit}
            onChange={onChange}
            onReady={onReady}
          />
        );
      case PaymentSystem.STRIPE:
        return (
          <CardElementStripe
            ref={ref}
            product={product}
            options={options}
            className={className}
            onSubmit={handleSubmit}
            onChange={onChange}
            onReady={onReady}
          />
        );
      case PaymentSystem.SOLIDGATE:
        return (
          <CardElementTruegate
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            onError={onError}
          />
          // <CardElementSolidgate
          //   ref={ref}
          //   product={product}
          //   options={options}
          //   className={className}
          //   onChange={onChange}
          //   onReady={onReady}
          //   onSubmit={handleSubmit}
          //   onCheckPrepaid={onCheckPrepaid}
          //   onSuccess={onSuccess}
          //   onError={onError}
          //   onApplePayClick={onApplePayClick}
          //   onApplePaySubmit={onApplePaySubmit}
          //   onGooglePayClick={onGooglePayClick}
          //   onGooglePaySubmit={onGooglePaySubmit}
          //   onUserInfoChange={onUserInfoChange}
          //   formStyles={formStyles}
          //   inputStyles={inputStyles}
          //   labelStyles={labelStyles}
          //   submitStyles={submitStyles}
          //   notEmptyLabelStyles={notEmptyLabelStyles}
          // />
        );
      case PaymentSystem.PADDLE:
        return (
          <CardElementPaddle
            ref={ref}
            config={publicKeys?.paddle}
            product={product}
            options={options}
            className={className}
            onChange={onChange}
            onReady={onReady}
            onSuccess={onSuccess}
            onError={onError}
            onUserInfoChange={onUserInfoChange}
            onEvent={onEvent}
          />
        );
      case PaymentSystem.TRUEGATE:
        return (
          <CardElementTruegate
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            onError={onError}
          />
        );
      default:
        return null;
    }
  }
);

CardElement.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onReady: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onApplePayClick: PropTypes.func,
  onApplePaySubmit: PropTypes.func,
  onGooglePayClick: PropTypes.func,
  onGooglePaySubmit: PropTypes.func,
  onUserInfoChange: PropTypes.func,
  onEvent: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.object,
};

export default CardElement;
