import type { FC } from 'react';

import { t } from '@web-solutions/module-localization';

import { baseTKey } from './constants';

import classes from './style.module.scss';

export const Divider: FC = () => (
  <div className={classes.dividerWrapper}>
    <div className={classes.line} />
    {t(`${baseTKey}`)}
    <div className={classes.line} />
  </div>
);
