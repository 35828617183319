import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';
import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { setAlreadyCreatedAccount } from '../../store/actions';

import { useBaseProcesses } from '../../hooks/use-base-processes';

import { ANALYTICS } from '../constants'

import { checkIsPrivateAppleEmail, mapProviderName } from '../utils';

import { useCheckProviderExists } from './use-check-provider-exists'

export type InitialData = {
  email: string,
  password: string,
  confirm_password: string,
}

export const useCreateAccount = ({ email, linkAuthResult, onNextScreen }: { email: string, linkAuthResult?: any, onNextScreen: () => void }) => {
  const dispatch = useDispatch();

  const {
    data,
    validation,
    handleChange,
  } = useBaseProcesses(email);

  const [erorPopup, setErorPopup] = useState({
    show: false,
    provider: '',
  });

  const [successPopup, setSuccessPopup] = useState({
    show: false,
    provider: '',
  });

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.OPEN);
  }, []);

  useEffect(() => {
    if (linkAuthResult instanceof Error) {
      // @ts-ignore
      Analytics.trackEvent(ANALYTICS, EVENT_ACTION.ERROR, { error: linkAuthResult?.message, code: linkAuthResult?.code });

      // @ts-ignore
      setErorPopup({ show: true, provider: mapProviderName(linkAuthResult?.customData?._tokenResponse?.providerId) });
    } else if (linkAuthResult) {
      const providerData = linkAuthResult?.providerData?.[0]

      const provider = mapProviderName(providerData?.providerId)

      if (linkAuthResult?.email) {
        Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUCCESS, { provider, isPrivateEmail: provider === 'apple_id' ? checkIsPrivateAppleEmail(providerData?.email) : undefined });

        stringifyUrlParams({ alreadyCreatedAccount: null });
        dispatch(setAlreadyCreatedAccount(true));
        setSuccessPopup({ show: true, provider });
      } else {
        Analytics.trackEvent(ANALYTICS, EVENT_ACTION.ERROR, { code: 'no-email', provider });

        setErorPopup({ show: true, provider });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkAuthResult]);

  useCheckProviderExists({ linkAuthResult, onNextScreen })

  return {
    data,
    validation,
    erorPopup,
    successPopup,
    setErorPopup,
    setSuccessPopup,
    handleChange
  }
}