import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { Title, Button, Image } from '@web-solutions/core/ui-elements';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t } from '../../../../../localization';

import { baseTKey } from '../../contants';

import { ReactComponent as IconClose } from '../../../../components/icons/close-icon.svg';

import { IMAGES } from './images';

import classes from './style.module.scss';

interface ReasonPopUpProps {
  isOpen: boolean,
  onClose: () => void,
  text: string,
}

const tKey = `${baseTKey}.result_popup`;

const ResultPopUp: React.FC<ReasonPopUpProps> = ({
  isOpen,
  text,
  onClose,
}) => {

  useEffect(() => {
    if (isOpen) {
      Analytics.trackEvent('manage_review_result_popup', EVENT_ACTION.OPEN);
    }
  }, [isOpen]);

  const handleClose = () => {
    Analytics.trackEvent('manage_review_result_popup', EVENT_ACTION.SUCCESS);
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <Image img={IMAGES.ASTROLOGER} alt="astrologer" />
      <Title level="h2" className={classes.title}>{t(`${tKey}.title`)}</Title>
      <button type="button" onClick={handleClose} className={classes.close}>
        <IconClose width="16px" height="16px" />
      </button>
      <Title level="h3" className={classes.subtitle}>{t(`${tKey}.subtitle`)}</Title>
      <div className={classes.text_block}>
        <p className={classes.text}>{text}</p>
      </div>
      <Button
        onClick={handleClose}
        iconRight={false}
        className={classes.button}
        title={t(`${tKey}.button`)}
        titleClassName={classes.button__title}
      />
    </Modal>
  )
};

export default ResultPopUp;