import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Analytics from '@web-solutions/module-analytics';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title } from '@web-solutions/core/ui-elements';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';

import { t } from '../../../localization';

import { selectSubscription, useSliceDispatch, goAppLink, reactivate } from '../../slice';
import { ROUTES } from '../../constants/routes';
import { ReactComponent as ArrowBackIcon } from '../../components/icons/arrow-back.svg';
import { useNavigateManage } from '../../hooks/use-navigate-next-screen';
import { SuccessModal } from '../../components/success-modal';
import { useManageContext } from '../../hooks/use-manage-context';
import { SubscriptionStatus } from '../../types';
import { useUnsubscribe } from '../../hooks/use-unsubscribe';

import {
  tKey,
  ANALYTICS_MAIN,
  ANALYTICS_REACTIVATE
} from './constants'

import { CurrentPlan } from './current-plan';
import { Features } from './features';
import { Info } from './info';

import { useGetUrl } from './hooks/use-get-url';

import classes from './style.module.scss';
import { Footer } from './footer';

import '@web-solutions/core/containers/page-transition/styles.scss'

export const ManageMainPage: React.FC = () => {
  const dispatch = useSliceDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { mngSubMainPage } = useRemoteConfig();
  const { mainContent, fastCancelMode, withFeatures = true } = useManageContext();

  const { navigateNextScreen } = useNavigateManage();

  const settingsUrl = useGetUrl();

  const { cancelSub, setErrorMessage, errorMessage } = useUnsubscribe(ANALYTICS_MAIN);

  const subscription = useSelector(selectSubscription);

  const [success, setSuccess] = useState(false);
  const [statusCode, setStatusCode] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS_MAIN, EVENT_ACTION.OPEN);
  }, []);

  const handleReactivateClick = () => {
    Analytics.trackEvent(ANALYTICS_REACTIVATE, EVENT_ACTION.SUBMIT, { productId: subscription?.product });
    dispatch(reactivate())
      .unwrap()
      .then(() => {
        Analytics.trackEvent(ANALYTICS_REACTIVATE, EVENT_ACTION.SUCCESS, { productId: subscription?.product });
        setSuccess(true);
      })
      .catch((e) => {
        Analytics.trackEvent(ANALYTICS_REACTIVATE, EVENT_ACTION.ERROR, { productId: subscription?.product, code: e?.code, message: e?.message });
        setError(e?.message || t('manage.error.text'));
        setStatusCode(e?.code)
      });
  }

  const handleChangePlanClick = () => {
    navigate({
      pathname: ROUTES.PLANS,
      search: location.search,
    },
      {
        state: {
          fromMain: true,
        }
      });
  };

  const handleCancelSubscriptionClick = () => {
    if (fastCancelMode) {
      Analytics.trackEvent(ANALYTICS_MAIN, 'continue', { unsubscribe: true });
      cancelSub();
    } else {
      Analytics.trackEvent(ANALYTICS_MAIN, 'continue');
      navigateNextScreen();
    }
  };

  const handleReturnToAppClick = () => {
    Analytics.trackEvent(ANALYTICS_MAIN, 'return_to_app');
    dispatch(goAppLink());
  };

  const handleCloseErrorClick = () => {
    Analytics.trackEvent(`${ANALYTICS_MAIN}_error_modal`, 'close');
    setError('');
    setErrorMessage('');
  }

  const handleBackClick = () => {
    Analytics.trackEvent(ANALYTICS_MAIN, 'back_to_app');
    window.location.href = settingsUrl;
  }

  const isPlanDetailsAvailable = Boolean(mngSubMainPage.planDetailsEnabled && subscription && !(subscription?.status === SubscriptionStatus.CANCELED || subscription?.status === SubscriptionStatus.EXPIRED))
  const isSubUpdating = Boolean(mngSubMainPage?.updatingDelay && subscription?.created_at && dayjs.utc(subscription?.created_at).add(mngSubMainPage?.updatingDelay, 'minutes').diff(dayjs.utc(), 'seconds') >= 0)

  return (
    <>
      <button className={classes.back_button} onClick={handleBackClick}>
        <ArrowBackIcon />
      </button>
      <div className={classes.wrap}>
        <Title level='h1'>
          {t(`${tKey}.title`)}
        </Title>
        {Boolean(subscription) && (
          <CurrentPlan
            withoutBgImage={Boolean(mngSubMainPage?.updatingDelay)}
            isSubUpdating={isSubUpdating}
            isPlanDetailsAvailable={isPlanDetailsAvailable}
            onCancelClick={handleCancelSubscriptionClick}
            onChangePlanClick={handleChangePlanClick}
          />
        )}
        {Boolean(mngSubMainPage?.showMainContent) && mainContent}
        {Boolean(mngSubMainPage?.updatingDelay) && (
          <Info />
        )}
        {withFeatures && !mngSubMainPage?.updatingDelay && (
          <Features />
        )}
        {!isSubUpdating && !mngSubMainPage?.updatingDelay && (
          <Footer
            tKey={tKey}
            onCancelSubscriptionClick={handleCancelSubscriptionClick}
            onChangePlanClick={handleChangePlanClick}
            onReactivateClick={handleReactivateClick}
            onReturnToAppClick={handleReturnToAppClick}
          />
        )}
      </div>
      <SuccessModal
        category="manage_reactivate_success_modal"
        tKey="manage.plan.reactivated_modal"
        isOpen={success}
      />
      <ErrorPopup
        visible={!!error || !!errorMessage}
        tKey={errorMessage ? 'manage.terminate_subscription.error_modal' : undefined}
        buttonTitle='OK'
        statusCode={statusCode}
        onClose={handleCloseErrorClick}
        onSubmit={handleCloseErrorClick}
      />
    </>
  );
};
