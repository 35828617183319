import {
  EmailAuthProvider,
  OAuthProvider,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  getAuth,
  getRedirectResult,
  linkWithCredential,
  linkWithRedirect,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import DeviceProps from '@magnus/react-native-device-props';

import Analytics from '@web-solutions/module-analytics';

import { updateUserEmail } from '@web-solutions/base-app/api/auth';

import request from '@web-solutions/core/utils/network';

//@ts-ignore
import { authFirebase } from 'src/api/auth';

import type { FirebaseProviderId } from '../create-account/types';

export const confirmPassword = async (oobCode: string, newPassword: string) => {
  await confirmPasswordReset(getAuth(), oobCode, newPassword)
}

export const signInUser = async (email: string, password: string) => {
  try {
    const r = await signInWithEmailAndPassword(getAuth(), email, password);
    return r?.user?.uid;
  } catch (error) {
    console.error("ERROR", error);
  }
};

export const reSendPasswordReset = async (email: string, platform: string) => {
  await request.post('/auth/reset-password', { email, platform });
}

export const checkIsCodeValid = async (oobCode: string) => {
  const auth = getAuth();
  return checkActionCode(auth, oobCode).then()
}

export const handleRecoverEmail = (oobCode: string) => {
  const auth = getAuth();
  let restoredEmail: string | null | undefined = null;

  checkActionCode(auth, oobCode)
    .then((info) => {
      restoredEmail = info['data']['email'];
      return applyActionCode(auth, oobCode);
    })
    .then(() => {
      if (restoredEmail) {
        sendPasswordResetEmail(auth, restoredEmail)
          .catch((error) => {
            console.error('ERROR', error);
          });
      }
    })
    .catch((error) => {
      console.error('ERROR', error);
    });
}

export const checkAccountAlreadyExists = async (email: string): Promise<Record<'is_account_exist', boolean>> => request.post('/auth/check-account', { email });

export const updateFirebaseUserEmail = ({ email, analyticParams = {} }: { email: string, analyticParams?: Record<string, string> }) => {
  Analytics.trackEvent('firebase_auth', 'update_email_submit', { email, ...analyticParams });

  updateUserEmail(email)
    .then(() => {
      Analytics.trackEvent('firebase_auth', 'update_email_success', { email, ...analyticParams });
    })
    .catch((error) => {
      Analytics.trackEvent('firebase_auth', 'update_email_error', { error: error?.message, code: error?.code, email, ...analyticParams });
    });
}

export const checkRedirectResult = () => {
  return getRedirectResult(getAuth())
    .then((result) => {
      if (result) {
        const credential = OAuthProvider.credentialFromResult(result)

        if (credential) {
          return result.user;
        }
      }
    })
    .catch((error) => {
      console.warn(error);

      return error;
    });
}

export async function signUpWithSocials(providerId: FirebaseProviderId): Promise<void> {
  const provider = new OAuthProvider(providerId);
  const auth = getAuth()

  if (!auth.currentUser && authFirebase) {
    const idfm = await DeviceProps.getIDFM()

    await authFirebase(idfm)
  }

  await linkWithRedirect(auth.currentUser!, provider)
}

async function linkWithEmailAndPassword(currentUser: any, email: string, password: string): Promise<boolean> {
  const credential = EmailAuthProvider.credential(email, password);
  await linkWithCredential(currentUser, credential);
  return true;
}

export async function signUpWithEmail(email: string, password: string): Promise<boolean> {
  try {
    return await linkWithEmailAndPassword(getAuth().currentUser, email, password);
  } catch (error) {
    const idfm = await DeviceProps.getIDFM();
    await authFirebase(idfm);
    return await linkWithEmailAndPassword(getAuth().currentUser, email, password);
  }
}
