import { Button, Modal, Text, Title, LinkButton } from "@web-solutions/core/ui-elements"

import { t } from '../../../localization';

import { ReactComponent as IconClose } from '../icons/close-icon.svg';

import classes from './style.module.scss';

interface ErrorModalProps {
  tKey: string,
  isOpen: boolean,
  onButtonClick: () => void;
  onCloseClick: () => void;
  message?: string,
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpen,
  tKey,
  message,
  onButtonClick,
  onCloseClick
}) => {
  const linkButtonText = t(`${tKey}.link_button`, { defaultValue: '' })

  return (
    <Modal
      isOpen={isOpen}
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <Title level="h2" className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      {message && (
        <Text className={classes.text}>
          {message}
        </Text>
      )}
      <button
        type="button"
        className={classes.close}
        onClick={onCloseClick}
      >
        <IconClose width="16px" height="16px" />
      </button>
      <Button
        onClick={onButtonClick}
        iconRight={false}
        className={classes.button}
        title={t(`${tKey}.button`)}
        titleClassName={classes.button__title}
      />
      {linkButtonText && (
        <LinkButton className={classes.linkButton} onClick={onCloseClick}>
          {linkButtonText}
        </LinkButton>
      )}
    </Modal>
  )
}
