import type { ComponentProps, FC, ReactElement } from 'react'
import classNames from 'classnames';

import classes from './style.module.scss';

export interface SocialButtonBaseProps extends ComponentProps<'button'> {
  icon: ReactElement
}

export const SocialButtonBase: FC<SocialButtonBaseProps> = ({ className, children, icon, ...props }) => (
  <button type='button' className={classNames(classes.socialButton, className)} {...props}>
    {icon}
    {children}
  </button>
);