import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { T, t, tm } from '@web-solutions/module-localization';

import { type PriceTypes } from '@web-solutions/core/constants/remote-config';

import { getCurrencySymbols, } from '@web-solutions/react-billing/utils/prices';
import { createVATPrice } from '@web-solutions/core/utils/vat';
import { Text } from '@web-solutions/core/ui-elements';

import { useRemoteConfig } from 'core/hooks/use-remote-config';
import TrialsLeft, { TrialsLeftType } from 'core/payment/components/trials-left';
import TrialPrice from 'core/payment/base/product-info/components/trial-price';
import { ProductDetails } from 'core/store/billing/selectors';
import { COUNTRY_VAT } from 'core/payment/constants';
import { getProductTitle } from 'core/utils/products';

import classes from '../../style.module.scss';
import { VatPrice } from '../vat-price';

import { ReactComponent as Bullet } from '../../icons/bullet.svg'

interface PricesProps {
  product: ProductDetails;
  priceText: string;
}

const prevAmountTypes: Record<PriceTypes, 'prevPriceAmount' | 'prevWeekPriceAmount' | 'prevDayPriceAmount'> = {
  today: 'prevPriceAmount',
  week: 'prevWeekPriceAmount',
  day: 'prevDayPriceAmount',
}

export const vatPriceTypes: Record<PriceTypes, 'vatPrice' | 'vatWeekPrice' | 'vatDayPrice'> = {
  today: 'vatPrice',
  week: 'vatWeekPrice',
  day: 'vatDayPrice',
}

export const vatTypes: Record<PriceTypes, 'vat' | 'weekVat' | 'dayVat'> = {
  today: 'vat',
  week: 'weekVat',
  day: 'dayVat',
}

const tKey = 'core.payment_popup';

export const Prices: React.FC<PricesProps> = ({
  product,
  priceText,
}) => {

  const features = t(`${tKey}.features`, { returnObjects: true }) as unknown as string[];

  const countryCode = useSelector((state: any) => state?.billing?.countryCode);

  const {
    showFeaturesBlock,
    showPriceDiscount,
    showFullPrice,
    totalPriceType,
    configVAT,
  } = useRemoteConfig();

  const {
    actualPriceText,
    title,
    currency,
    textLineThrough,
    discount,
    isTrial,
    trialDays,
    trialPrice,
    period,
    aboutTrialText,
    savedPrice,
    vatTrial,
    vatTrialPrice,
    promocode,
    price
  } = product;

  const isSimpleVAT = configVAT.view === 'simple';

  const displayTaxes = configVAT.display && !!COUNTRY_VAT[countryCode];

  const amountLineThrough = textLineThrough ? product[prevAmountTypes[totalPriceType]] : '';

  const { currencySuffix, currencySymbol } = getCurrencySymbols(currency);
  const textLineThroughByPeriod = `${currencySymbol}${amountLineThrough}${currencySuffix}`

  const totalPriceKey = displayTaxes && !isSimpleVAT ? 'total_item' : isTrial ? 'today' : totalPriceType;

  const vatPrice = product[vatPriceTypes[totalPriceType]];

  const vat = product[vatTypes[totalPriceType]];

  const { price: priceLineThrough } = countryCode ? createVATPrice({ amount: amountLineThrough, currency, countryCode }) : { price: '' };

  const priceString = displayTaxes && isSimpleVAT ? priceText : vatPrice;

  let todayPrice = !isTrial ? priceString : trialPrice;

  if (displayTaxes && !isSimpleVAT && isTrial) {
    todayPrice = vatTrialPrice
  }

  const vatPriceText = isTrial ? trialPrice : priceText;
  const defaultTitle = getProductTitle(product);

  const productTodayPrice = actualPriceText ? actualPriceText : todayPrice;
  const actualPrice = actualPriceText ? actualPriceText : price;
  const actualTrialPrice = actualPriceText ? actualPriceText : trialPrice;

  return (
    <>
      <div className={classes.block}>
        {showPriceDiscount && (
          <div className={classes.row}>
            <p className={classes.aboutItemText}>
              {tm(title, defaultTitle)}
            </p>
            <div className={classes.leftColumn}>
              <span className={classNames(classes.lineThrough, classes.disabled)}>
                {tm(textLineThrough, '', { ...product })}
              </span>
            </div>
          </div>
        )}

        {showPriceDiscount && !!discount && !!savedPrice && (
          <div className={classes.row}>
            <p className={classes.aboutItemText}>
              <b>{t(`${tKey}.personal_discount`, { discount: discount })}</b>
            </p>
            <div className={classes.leftColumn}>
              <b>{tm(savedPrice, '', { ...product })}</b>
            </div>
          </div>
        )}

        {!showPriceDiscount && isTrial && (
          <div className={classes.row}>
            <p className={showFullPrice ? classNames(classes.aboutItemGrayText, classes.smallText) : classes.aboutItemText}>
              {tm(aboutTrialText, `${tKey}.trial`, { days: trialDays })}
            </p>
            <div className={classes.leftColumn}>
              <TrialsLeft type={TrialsLeftType.TEXT} />
              <TrialPrice
                className={showFullPrice ? classNames(classes.aboutItemGrayText, classes.smallText) : classes.aboutItemText}
                textLineThrough={!showPriceDiscount && !showFullPrice ? tm(textLineThrough, '', { ...product }) : ''}
                trialPrice={configVAT.display ? vatTrialPrice : actualTrialPrice}
              />
            </div>
          </div>
        )}

        {showFullPrice && (
          <div className={classNames(classes.row, classes.aboutItemGrayText, classes.smallText)}>
            <p>
              {t(`core.plans.items_titles.${period}`)}
            </p>
            <div className={classes.leftColumn}>
              {actualPrice}
            </div>
          </div>
        )}

        {promocode?.discount && promocode?.discountPrice && (
          <div className={classes.promo_discount}>
            <p>
              {t(`${tKey}.promocode.discount`, { discount: promocode?.discount })}
            </p>
            <p className={classes.discount_price}>
              {`-${currencySymbol}${promocode?.discountPrice}${currencySuffix}`}
            </p>
          </div>
        )}

        {isTrial && showFeaturesBlock && (
          <div className={classes.features}>
            {features.map((item) => (
              <div key={item} className={classes.feature}>
                <Bullet className={classes.icon} />
                <Text className={classes.feature_text}>{item}</Text>
                <div className={classes.dots} />
                <Text className={classNames(classes.feature_text, classes.feature_price)}>{`${currencySuffix} 0${currencySymbol}`}</Text>
              </div>
            ))}
          </div>
        )}

        <div className={classes.row}>
          <p className={classNames(classes.aboutItemBlackText, { [classes.largeText]: showFullPrice })}>
            <T
              k={`${tKey}.total.${totalPriceKey || 'today'}`}
              components={{ span: <span className={classes.smallText} /> }}
            />
          </p>
          <p className={classNames(classes.aboutItemBlackText, { [classes.largeText]: showFullPrice })}>
            {!isTrial && !!textLineThrough && !showPriceDiscount && !showFullPrice &&
              <span className={classes.lineThrough}>
                {displayTaxes ? priceLineThrough : textLineThroughByPeriod}
              </span>
            }
            {productTodayPrice}
          </p>
        </div>

        <VatPrice
          priceText={vatPriceText}
          vat={isTrial ? vatTrial : vat}
          vatPrice={isTrial ? vatTrialPrice : vatPrice}
        />
      </div >
    </>
  )
}