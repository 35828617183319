import React from 'react';

import { Title } from '@web-solutions/core/ui-elements';

import { t } from '../../../../../localization';

import { useManageContext } from '../../../../hooks/use-manage-context';

import classes from './style.module.scss';

const tKey = 'manage.main.features';

export const Features: React.FC = () => {
  const { featuresTile } = useManageContext();

  return (
    <div className={classes.wrapper}>
      <Title level={'h2'}>{t(`${tKey}.title`)}</Title>
      <div className={classes.content}>
        <div className={classes.warning}>
          {t(`manage_v2.main.features.warning`)}
        </div>
        {featuresTile}
      </div>
    </div>
  )
};
