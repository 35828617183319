import { useSelector } from 'react-redux';
import type { FC, ComponentProps } from 'react'
import classNames from 'classnames';

import { T } from "@web-solutions/module-localization";

import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { COUNTRY_VAT } from 'core/payment/constants';

import classes from '../../style.module.scss';
import { VatPrice } from '../vat-price';

import { tKey } from './constants'
import type { SimpleViewProps } from './simple-view'

type TotalPriceProps = Pick<SimpleViewProps, 'vatPrice' | 'vat' | 'fullPrice' | 'actualPrice' | 'mode'> & ComponentProps<'div'>

export const TotalPrice: FC<TotalPriceProps> = ({ vatPrice, vat, className, fullPrice, mode, actualPrice, ...props }) => {
  const { configVAT } = useRemoteConfig();

  const isSimple = configVAT.view === 'simple';


  const countryCode = useSelector((state: any) => state?.billing?.countryCode)

  const displayTaxes = configVAT.display && !!COUNTRY_VAT[countryCode];

  const priceString = displayTaxes && !isSimple ? vatPrice : actualPrice;

  return (
    <div className={classNames(classes.totalPrice, className)} {...props}>
      <div className={classes.row}>
        <p className={classes.aboutItemBlackText}>
          <T k={isSimple ? `${tKey}.total.total` : `${tKey}.total.total_item`} />
        </p>
        <p className={classes.aboutItemBlackText}>
          {mode === 'short' && <span className={classes.full_price}>{fullPrice}</span>}
          {priceString}
        </p>
      </div>
      <VatPrice
        priceText={actualPrice}
        vat={vat}
        vatPrice={vatPrice}
      />
    </div>
  )
}
