import ReactBilling, { type PaymentSystem, } from '@web-solutions/react-billing';

import API from './api';

import { getExtraParams } from './utils';

import {
  ApplePayOrderBillMethod,
  ChangePlanBillMethod,
  CreateCheckoutBillMethod,
  CreateCustomerBillMethod,
  CreateOneClickPaymentBillMethod,
  CreateOrderBillMethod,
  GetPaymentParamsBillMethod,
  InitAlternativePaymentBillMethod,
  PauseSubscriptionBillMethod,
  RequestCancelBillMethod,
  SubmitCancelSubscriptionBillMethod,
  SubscribeBillMethod,
  SubscribeOnOneClickBillMethod,
} from './types'

const Billing = {
  apiAuth: '',

  paymentSystemProject: '',
  paymentSystemType: '',

  setApiAuth(apiAuth: string) {
    this.apiAuth = apiAuth;
  },

  getPaymentParams({ paymentSystem, paymentProject }: GetPaymentParamsBillMethod = { paymentSystem: '', paymentProject: '' }) {
    return {
      payment_system_type: paymentSystem || this.paymentSystemType,
      payment_system_project: paymentProject || this.paymentSystemProject,
    };
  },

  getHeaders() {
    return {
      Authorization: this.apiAuth,
    };
  },

  async init(paymentSystem: PaymentSystem, paymentProject: string = '') {
    this.paymentSystemType = paymentSystem;
    this.paymentSystemProject = paymentProject;

    const headers = this.getHeaders();

    const keys = await API.getPublicKey(this.getPaymentParams(), headers);

    return ReactBilling.init(paymentSystem, keys);
  },

  async getPrices(pricesIds: string[], { paymentSystem }: { paymentSystem: PaymentSystem | '' } = { paymentSystem: '' }) {
    const headers = this.getHeaders();
    const params = {
      search: `external_id:${pricesIds.join(',')}`,
      searchFields: 'external_id:in',
      ...this.getPaymentParams({ paymentSystem }),
    };
    return (await API.getPrices(params, headers)).data;
  },

  async createOrder({
    one_time_product,
    one_time_products,
    productId,
    currency,
    paymentSystem,
    trigger,
    metadata,
    solidMetadata,
    is_sending_terminate_links,
    is_sending_trial_end,
    decline_prepaid_card,
    subscription_products,
    orderDescription,
  }: CreateOrderBillMethod) {
    const extra = await getExtraParams();

    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...extra,
      one_time_product,
      trigger,
      order_description: orderDescription,
      metadata: JSON.stringify(metadata),
      solid_metadata: solidMetadata,
      one_time_products: one_time_products || undefined,
      product_id: productId,
      currency,
      is_sending_terminate_links,
      is_sending_trial_end,
      decline_prepaid_card,
      subscription_products,
    };

    return API.createOrder(body, this.getHeaders());
  },

  async initAlternativePayment({
    paymentMethod,
    paymentSystem,
    trigger,
    products,
    subscription_products,
    currency,
    metadata,
    one_time_products,
    is_sending_terminate_links,
    is_sending_trial_end,
    order_description,
    solid_metadata,
    first_name,
    last_name,
    taxpayer_id,
    success_url,
    fail_url,
    return_url,
  }: InitAlternativePaymentBillMethod) {
    const extra = await getExtraParams();

    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...extra,
      products,
      subscription_products,
      solid_metadata,
      currency,
      metadata: JSON.stringify(metadata),
      one_time_products,
      trigger,
      is_sending_terminate_links,
      is_sending_trial_end,
      payment_method: paymentMethod,
      customer_first_name: first_name,
      customer_last_name: last_name,
      order_description,
      taxpayer_id,
      success_url,
      fail_url,
      return_url,
    };

    return API.initAlternativePayment(body, this.getHeaders())
  },

  async createCheckout({ productId, checkoutId, paymentMethod, successUrl, paymentSystem }: CreateCheckoutBillMethod) {
    const extra = await getExtraParams();
    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...extra,
      product_id: productId,
      checkout_id: checkoutId,
      payment_method: paymentMethod,
      success_url: successUrl,
    };

    return API.createCheckout(body, this.getHeaders());
  },

  async createCustomer({ email, paymentSystem, paymentProject }: CreateCustomerBillMethod) {
    const headers = this.getHeaders();
    const body = {
      ...this.getPaymentParams({ paymentSystem, paymentProject }),
      ...(email && { email }),
    };

    return (await API.createCustomer(body, headers)).data;
  },

  async subscribe({ paymentSystem, metadata, is_sending_terminate_links, is_sending_trial_end, decline_prepaid_card, ...formData }: SubscribeBillMethod) {
    const headers = this.getHeaders();
    const extra = await getExtraParams();

    const body = {
      ...extra,
      ...formData,
      ...this.getPaymentParams({ paymentSystem }),
      metadata: JSON.stringify(metadata),
      is_sending_terminate_links,
      decline_prepaid_card,
      is_sending_trial_end
    };

    return (await API.createSubscription(body, headers)).data;
  },

  async subscribeOnOneClick({ productId, metadata, ...params }: SubscribeOnOneClickBillMethod) {
    const extra = await getExtraParams();

    const body = {
      ...extra,
      ...this.getPaymentParams(),
      ...params,
      metadata: JSON.stringify(metadata),
      product_id: productId,
    };

    const response = await API.subscribeOnOneClick(body, this.getHeaders())

    return response
  },

  async applePayOrder({ paymentSystem, metadata, solidMetadata, ...formData }: ApplePayOrderBillMethod) {
    const headers = this.getHeaders();
    const extra = await getExtraParams();

    const body = {
      ...extra,
      ...formData,
      customer_email: formData.email,
      metadata: JSON.stringify(metadata),
      solid_metadata: solidMetadata,

      ...this.getPaymentParams({ paymentSystem }),
    };

    return API.createApplePayOrder(body, headers);
  },

  async getSubscriptions(actualized?: boolean) {
    const headers = this.getHeaders();

    if (actualized) {
      try {
        return await API.getActualizedSubscriptions(headers);
      } catch (error) {
        return await API.getAllSubscriptions(headers);
      }
    }

    return API.getAllSubscriptions(headers);
  },

  async requestCancel({ email, trigger, isEnableLang }: RequestCancelBillMethod) {
    const body = {
      email,
      trigger,
      is_enable_lang: isEnableLang
    };

    return API.cancelSubscription(body);
  },

  async submitCancelSubscription(params: SubmitCancelSubscriptionBillMethod) {
    return API.submitCancelSubscription(params);
  },

  async pauseSubscription({ paymentSystem, ...params }: PauseSubscriptionBillMethod) {
    return API.pauseSubscription({ ...params, ...this.getPaymentParams({ paymentSystem }), }, this.getHeaders());
  },

  async createOneClickPayment({
    amount,
    currency,
    force3ds,
    productCode,
    description,
    successUrl,
    failUrl,
    trigger,
    metadata,
    solidMetadata,
    withTransactionDetailsEmail,
  }: CreateOneClickPaymentBillMethod) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      withTransactionDetailsEmail,
      force3ds,
      amount,
      description,
      currency,
      trigger,
      metadata: JSON.stringify(metadata),
      solid_metadata: solidMetadata,
      success_url: successUrl,
      fail_url: failUrl,
      product_code: productCode,
    };

    return API.createOneClickPayment(body, headers);
  },

  async getPaymentMethod() {
    const headers = this.getHeaders();
    const paymentMethod = await API.getPaymentMethod(headers);
    return paymentMethod?.data || paymentMethod;
  },

  async getOneTimePurchases() {
    const headers = this.getHeaders();
    const oneTimePurchases = await API.getOneTimePurchases(headers);
    return oneTimePurchases?.data || oneTimePurchases;
  },

  async sendAccessEmail(email: string) {
    const headers = this.getHeaders();
    const body = {
      email
    }

    return API.sendAccessEmail(body, headers);
  },

  async changePlan({ subscriptionId, productId, isNewSubscription, successUrl, failUrl, orderDescription }: ChangePlanBillMethod) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      subscriptionId,
      planCode: productId,
      isNewSubscription: !!isNewSubscription,
      order_description: orderDescription,
      success_url: successUrl,
      fail_url: failUrl,
    };

    return API.changePlan(body, headers);
  },

  async giveProduct(productId: string) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      product_code: productId,
    };

    return API.giveProduct(body, headers);
  }
};

export default Billing;
