import React from 'react';
import classNames from 'classnames';

import { LString, tm } from '@web-solutions/module-localization';

import classes from './style.module.scss';

import { ReactComponent as Icon } from './icons/preloader.svg';

interface Props {
  className?: string;
  withText?: boolean;
  text?: LString;
}

const Preloader: React.FC<Props> = ({ className, withText, text }) => (
  <div className={classNames(classes.wrapperSpinner, className)}>
    <span className={classes.spinner}>
      <Icon width="50px" height="50px" />
    </span>
    {withText && <span className={classes.text}>{tm(text, 'core.wait_text')}</span>}
  </div>
);

export default Preloader;
