import { useState, useRef } from 'react';
import classNames from 'classnames';

import { t, T } from '@web-solutions/module-localization';

import { Input } from '@web-solutions/core/ui-elements';

import classes from '../../create-account/components/email-form/style.module.scss';

import { ShowIcon, HideIcon } from './icons';

import { PasswordsData } from './types'

interface Props {
  data: PasswordsData;
  validation: { [key in keyof PasswordsData]: boolean };
  isReset?: boolean;
  withConfirm?: boolean
  withInputLabels?: boolean;
  onInputChange: (e: any) => void;
}

export const Passwords: React.FC<Props> = ({
  validation,
  data,
  isReset,
  withInputLabels,
  withConfirm = true,
  onInputChange
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const refPassword = useRef<HTMLInputElement>(null)
  const refConfirm = useRef<HTMLInputElement>(null)

  const tKey = isReset ? 'reset_password' : 'create_account';

  return (
    <div className={classNames(classes.fields_wrapper, { [classes.for_reset]: isReset })}>
      <label className={classes.password_field_wrap}>
        {withInputLabels && (
          <p className={classes.name}>
            <T k={`${tKey}.fields.password.title`} />
          </p>
        )}
        <div className={classes.field_with_icon}>
          <Input
            ref={refPassword}
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            name='password'
            value={data.password}
            placeholder={t(`${tKey}.fields.password.placeholder`)}
            className={classNames(classes.field, { [classes.error_border]: !validation.password })}
            onChange={onInputChange}
            autoFocus={isReset}
          />
          <div
            className={classes.field_icon}
            onClick={(e) => {
              e.preventDefault()

              setShowPassword(!showPassword)

              if (refPassword.current) {
                refPassword.current.blur()
              }
            }}
          >
            {data.password && (showPassword ? <ShowIcon /> : <HideIcon />)}
          </div>
        </div>
        {!validation.password && (
          <p className={classes.error}>
            {t(`${tKey}.errors_messages.password`)}
          </p>
        )}
      </label>
      {withConfirm && (
        <label className={classes.confirm_field_wrap}>
          {withInputLabels && (
            <p className={classes.name}>
              <T k={`${tKey}.fields.confirm.title`} />
            </p>
          )}
          <div className={classes.field_with_icon}>
            <Input
              ref={refConfirm}
              autoComplete="new-password"
              type={showConfirm ? 'text' : 'password'}
              name='confirm_password'
              value={data.confirm_password}
              placeholder={t(`${tKey}.fields.confirm.placeholder`)}
              className={classNames(classes.field, { [classes.error_border]: !validation.confirm_password })}
              onChange={onInputChange}
              disabled={!validation.password || data.password.length === 0}
            />
            <div
              className={classes.field_icon}
              onClick={(e) => {
                e.preventDefault()

                setShowConfirm(!showConfirm)

                if (refConfirm.current) {
                  refConfirm.current.blur()
                }
              }}
            >
              {data.confirm_password && (showConfirm ? <ShowIcon /> : <HideIcon />)}
            </div>
          </div>
          {
            !validation.confirm_password && (
              <p className={classes.error}>
                {t(`${tKey}.errors_messages.confirm`)}
              </p>
            )
          }
        </label >
      )}
    </div >
  )
};