import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Analytics from '@web-solutions/module-analytics';

import { t } from '@web-solutions/module-localization';

import { Button } from 'core/ui-elements';
import { Input } from 'core/ui-elements';

import { useEmail } from 'core/hooks/use-email';
import { useFocus } from 'core/hooks/use-focus';

import { initOrders } from 'core/store/billing/actions';

import { processEmail } from 'src/store/profile/actions'; // TODO

import classes from './style.module.scss';

import { ReactComponent as IconEdit } from './icons/icon-edit.svg';

const tKey = 'core.edit_email';

const EmailEditor = ({ className = null, isSimpleView, isInitOrdersAvailable = true }) => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.profile.email);
  const isSubmitByCard = useSelector((state) => state.billing.isSubmitByCard);
  const [value, isValid, setValue] = useEmail(email);
  const [isApply, setApply] = useState(!!email);
  const [isLoading, setLoading] = useState(false);
  const isPresenceEmail = (isSubmitByCard && value.length < 1) || (isSubmitByCard && !isValid) || (isSubmitByCard && !isApply)
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    if (isPresenceEmail) {
      setInputFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresenceEmail]);

  const handleInputChange = (e) => {
    const val = e.target.value;
    if (val !== email) {
      setApply(false);
    }
    setValue(val);
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    Analytics.trackEvent('edit_customer_email', 'btn_click');

    if (isApply || !isValid) {
      setApply(false);
      return;
    }

    try {
      setValue(value);
      setLoading(true);

      if (value !== email) {
        dispatch(processEmail(value))
        Analytics.trackEvent('edit_customer_email', 'success');

        if (isInitOrdersAvailable) {
          dispatch(initOrders());
        }
      }

      setApply(true);
    } catch (error) {
      dispatch(processEmail());
      Analytics.trackEvent('edit_customer_email', 'fail');
      const message = error?.data?.message ? error.data.message : t('core.errors.something_went_wrong');
      console.warn(error);
      toast(message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitForm = (e) => {
    Analytics.trackEvent('edit_customer', 'email_input_enter_click');
    handleButtonClick(e);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setApply(false);
  };

  const inputStyle = classNames(classes.input, {
    [classes.invalid]: isPresenceEmail || !value.length,
  });

  return (
    <div className={classNames(classes.wrapper, { [classes.applied]: isApply }, className)}>
      {isApply ?
        (
          <button type="button" className={classes.editClickableWrap} onClick={handleEditClick}>
            <p className={classes.description}>{t(`${tKey}.title`)}:</p>
            <div className={classes.rightSide}>
              <p className={classes.editEmail}>{value}</p>
              <IconEdit width={24} height={24} />
            </div>
          </button>
        ) :
        (
          <form action="#" noValidate onSubmit={handleSubmitForm} className={classes.form}>
            <Input
              ref={inputRef}
              autoFocus={isPresenceEmail}
              type="email"
              value={value}
              onChange={handleInputChange}
              className={inputStyle}
              placeholder={t(`${tKey}.placeholder`)}
              name="email"
              id="email"
            />
            <Button
              type="submit"
              className={classNames(classes.formButton, { [classes.simple_view]: isSimpleView })}
              disabled={!isValid || isLoading}
              title={t(`ok`)}
              titleClassName={classes.formButtonTitle}
            />
          </form>
        )}
    </div>
  );
};

export default EmailEditor;
