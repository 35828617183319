import _filter from 'lodash/filter';
import { createSelector } from 'reselect';

import { PaymentSystem } from '@web-solutions/react-billing';
import { ProductInfo, } from '@web-solutions/react-billing/constants';

import { ProductConfig, type RemoteConfig } from '../../constants/remote-config';

import { BillingState } from '.';

export type ProductDetails = ProductConfig & ProductInfo;

interface State {
  billing: BillingState,
  manage: {
    paymentSystem: PaymentSystem | null,
  },
  app: {
    paymentProject: string,
  },
  remoteConfig: RemoteConfig,
}

export function selectPaymentProject(state: State) {
  return state.remoteConfig.paymentProject || state.app.paymentProject || undefined;
}

export function selectPaymentSystem(state: State) {
  return state.manage?.paymentSystem || state.billing.paymentSystem || state.remoteConfig.paymentSystem || undefined;
}

export function selectInjectedPaymentMethod(state: State) {
  return state.billing.injectedPaymentMethod;
}

export function selectOneTimePurchases(state: State) {
  return state.billing.oneTimePurchases
}

export function selectPaymentMethod(state: State) {
  return state.billing.paymentMethod;
}

export function selectTrialPrice(state: State) {
  return state.billing.trialPrice;
}

export function selectMainProducts(state: State) {
  return state.billing.products;
}

export const selectProducts = createSelector([
  selectMainProducts,
  (state: State) => state.billing.specialOfferEndDate,
  (state: State) => state.billing.specialOfferProducts,
  selectTrialPrice,
],
  (products, specialOfferEndDate, specialOfferProducts, trialPrice,) => {
    if (specialOfferEndDate) {
      return specialOfferProducts;
    }
    const filtered = trialPrice ? _filter(products, product => product.trial_price_amount === `${trialPrice}`) : products;
    return filtered?.length ? filtered : products;
  });

export const selectAllProducts = createSelector([
  selectMainProducts,
  (state: State) => state.billing.specialOfferProducts,
  (state: State) => state.billing.noFundsOfferProducts,
  selectTrialPrice,
],
  (products, specialOfferProducts, noFundsOfferProducts, trialPrice) => {
    const filtered = products.filter((product) => trialPrice ? product.trial_price_amount === `${trialPrice}` : true)
    const mainProducts = filtered?.length ? filtered : products;
    return [...mainProducts, ...specialOfferProducts, ...noFundsOfferProducts,]
  });

export function selectPurchased(state: State) {
  return state.billing.purchased;
}

export function selectPurchase(state: State) {
  return state.billing.purchase;
}

export function selectLoading(state: State) {
  return state.billing.loading;
}

export function selectPaddleConfig(state: State) {
  return state.billing.paddleConfig
}

export function selectPostcode(state: State) {
  return state.billing.postcode
}

export function selectOrderDetails(state: State) {
  return state.billing.orderDetails
}

export function selectOrdersPayPal(state: State) {
  return state.billing.ordersPayPal
}

export function selectSpecialOfferProducts(state: State) {
  return state.billing.specialOfferProducts;
}

export function selectNoFundsOfferProducts(state: State) {
  return state.billing.noFundsOfferProducts;
}

export function selectNoFundsOfferEndDate(state: State) {
  return state.billing.noFundsOfferEndDate;
}

export function selectSpecialOfferEndDate(state: State) {
  return state.billing.specialOfferEndDate;
}

export function selectTrialsLeftCounter(state: State) {
  return state.billing.trialsLeftCounter;
}

export function selectUpsalesBasket(state: State) {
  return state.billing.upsalesBasket;
}

export function selectOneTimePurchaseId(state: State) {
  return state.billing.oneTimePurchaseId;
}

export function selectActiveProduct(state: State) {
  return state.billing.activeProduct;
}
