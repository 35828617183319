import { useMemo } from 'react';

import { getOperatingSystemName } from '@web-solutions/core/utils/operating-system';

import type { CreateAccountFlowStep } from '../types';

const os = getOperatingSystemName();

const FLOW_FILTER: Record<CreateAccountFlowStep, boolean> = {
  'email': true,
  'google': true,
  'apple_id': os === 'iOS' || os === 'Mac OS'
}

const SOCIAL_FLOW_ITEMS: CreateAccountFlowStep[] = ['google', 'apple_id']

export const useMapCreateAccountFlow = (createAccountFlow: CreateAccountFlowStep[]): (CreateAccountFlowStep | 'divider')[] => (
  useMemo(() => (
    createAccountFlow
      .filter((item) => FLOW_FILTER[item])
      .flatMap((flow, index, array) => index < array.length - 1 && !(SOCIAL_FLOW_ITEMS.includes(flow) && SOCIAL_FLOW_ITEMS.includes(array[index + 1])) ? [flow, 'divider'] : [flow])),
    [createAccountFlow])
)