import _omit from 'lodash/omit'
import classNames from 'classnames';

import { t, T } from '@web-solutions/module-localization';

import { Input, Button } from '@web-solutions/core/ui-elements';

import { baseTKey } from '../../constants'

import { Passwords } from '../../../components/passwords';

import { AttentionIcon } from './icons'

import type { EmailFormData } from './types'

import classes from './style.module.scss';

interface Props {
  data: EmailFormData;
  validation: { [key in keyof EmailFormData]: boolean };
  isEmailOnly: boolean;
  isLoading: boolean;
  withLetterIcon?: boolean;
  withInputLabels: boolean;
  onSubmit: (e: React.FormEvent) => void;
  onInputChange: (e: any) => void;
}

export const EmailForm: React.FC<Props> = ({ onSubmit, onInputChange, validation, data, isLoading, isEmailOnly, withLetterIcon, withInputLabels }) => {
  const isFormValid = Object.values(isEmailOnly ? validation : _omit(validation, 'confirm_password')).every(Boolean)
  const isFormСompletelyFilled = Object.values(isEmailOnly ? data : _omit(data, 'confirm_password')).every(Boolean)

  return (
    <form onSubmit={onSubmit} noValidate className={classNames(classes.form, { [classes.email_only]: isEmailOnly })}>
      <div className={classes.fields_wrapper}>
        <label className={classes.field_wrap}>
          {withInputLabels && (
            <p className={classes.name}>
              <T k={`${baseTKey}.fields.email.title`} />
            </p>
          )}
          <div className={classes.field_with_icon}>
            <Input
              autoComplete="email"
              type='email'
              name='email'
              value={data.email}
              placeholder={t(`${baseTKey}.fields.email.placeholder`)}
              className={classNames(classes.field, classes.email, { [classes.error_border]: !validation.email })}
              disabled
            />
            {!validation.email && (
              <AttentionIcon className={classNames(classes.field_icon, classes.attantion_icon)} />
            )}
          </div>
          {!validation.email && (
            <p className={classes.error}>
              {t(`${baseTKey}.errors_messages.email`)}
            </p>
          )}
        </label>
        <Passwords
          data={data}
          validation={validation}
          withInputLabels={withInputLabels}
          withConfirm={isEmailOnly}
          onInputChange={onInputChange}
        />
      </div>
      <Button
        type='submit'
        disabled={!isFormValid || !isFormСompletelyFilled || isLoading}
        className={classes.button}
        titleClassName={classNames(classes.button_title, { [classes.with_letter_icon]: withLetterIcon })}
        title={t(`${baseTKey}.form_button`)}
      />
    </form>
  )
};