import { type PaymentMethod } from "core/store/billing";

export const getAnalyticsPaymentMethod = (paymentMethod: PaymentMethod | null) => {
  if (!paymentMethod) {
    // for native paypal
    return 'paypal';
  }

  if (paymentMethod === 'paypal') {
    // for solidgate paypal
    return 'paypal_vault';
  }

  return paymentMethod;
}