import React from 'react';
import PropTypes from 'prop-types';

import { Elements as StripeElements } from '@stripe/react-stripe-js';
import {
  RecurlyProvider,
  Elements as RecurlyElements,
} from '@recurly/react-recurly';

import { PaymentSystem } from '../../constants';
import { getPaymentSystem, getPublicKeys } from '../../utils/storage';

const Provider = ({ children }) => {
  const paymentSystem = getPaymentSystem();
  const publicKeys = getPublicKeys();

  if (paymentSystem === PaymentSystem.RECURLY) {
    return (
      <RecurlyProvider publicKey={publicKeys.recurly?.public_key}>
        <RecurlyElements>{children}</RecurlyElements>
      </RecurlyProvider>
    );
  }
  if (paymentSystem === PaymentSystem.STRIPE) {
    const stripe = window.Stripe(publicKeys.stripe?.public_key);
    return <StripeElements stripe={stripe}>{children}</StripeElements>;
  }
  if (paymentSystem === PaymentSystem.SOLIDGATE) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  if (paymentSystem === PaymentSystem.PADDLE) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  if (paymentSystem === PaymentSystem.TRUEGATE) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return null;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
