import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Analytics from '@web-solutions/module-analytics';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title } from '@web-solutions/core/ui-elements';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';
import { useGetUrl } from '@web-solutions/manage-subscription/src/manage/screens/main/hooks/use-get-url';

import { t } from '../../../../localization';

import { selectSubscription, useSliceDispatch, goAppLink } from '../../../slice';
import { ROUTES } from '../../../constants/routes';
import { ReactComponent as ArrowBackIcon } from '../../../components/icons/arrow-back.svg';
import { useNavigateManage } from '../../../hooks/use-navigate-next-screen';
import { useManageContext } from '../../../hooks/use-manage-context';
import { SubscriptionStatus } from '../../../types';
import { useUnsubscribe } from '../../../hooks/use-unsubscribe';

import {
  tKey,
  ANALYTICS_MAIN,
} from './constants'

import { CurrentPlan } from './current-plan';
import { Features } from './features';

import classes from './style.module.scss';
import { Footer } from './footer';
import { InfoModal } from './info-modal';

import '@web-solutions/core/containers/page-transition/styles.scss'

export const ManageV2MainPage: React.FC = () => {
  const dispatch = useSliceDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { mngSubMainPage } = useRemoteConfig();
  const { fastCancelMode, withFeatures = true } = useManageContext();

  const { navigateNextScreen } = useNavigateManage();

  const settingsUrl = useGetUrl();

  const { cancelSub, setErrorMessage, errorMessage } = useUnsubscribe(ANALYTICS_MAIN);

  const subscription = useSelector(selectSubscription);

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS_MAIN, EVENT_ACTION.OPEN);
  }, []);

  const handleChangePlanClick = () => {
    Analytics.trackEvent(`${ANALYTICS_MAIN}_change_plan`, EVENT_ACTION.CLICK);
    navigate({
      pathname: ROUTES.PLANS,
      search: location.search,
    },
      {
        state: {
          fromMain: true,
        }
      });
  };

  const handleCancelSubscriptionClick = () => {
    if (fastCancelMode) {
      Analytics.trackEvent(ANALYTICS_MAIN, 'continue', { unsubscribe: true });
      cancelSub();
    } else {
      Analytics.trackEvent(ANALYTICS_MAIN, 'continue');
      navigateNextScreen();
    }
  };

  const handlePauseSubscriptionClick = () => {
    Analytics.trackEvent(ANALYTICS_MAIN, 'continue');
    navigate(
      {
        pathname: ROUTES.OFFER_FREE_PERIOD,
        search: location.search,
      },
      { state: { fromMain: true, } }
    );
  };

  const handleReturnToAppClick = () => {
    Analytics.trackEvent(ANALYTICS_MAIN, 'return_to_app');
    dispatch(goAppLink());
  };

  const handleCloseErrorClick = () => {
    Analytics.trackEvent(`${ANALYTICS_MAIN}_error_modal`, 'close');
    setErrorMessage('');
  }

  const handleBackClick = () => {
    Analytics.trackEvent(ANALYTICS_MAIN, 'back_to_app');
    window.location.href = settingsUrl;
  }

  const handleMoreClick = () => {
    setInfoModalOpen(true);
  }

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false);
  }

  const isPlanDetailsAvailable = Boolean(mngSubMainPage.planDetailsEnabled && subscription && !(subscription?.status === SubscriptionStatus.CANCELED || subscription?.status === SubscriptionStatus.EXPIRED))

  return (
    <>
      <button className={classes.back_button} onClick={handleBackClick}>
        <ArrowBackIcon />
      </button>
      <div className={classes.wrap}>
        <Title level='h1'>
          {t(`${tKey}.title`)}
        </Title>
        {Boolean(subscription) && (
          <CurrentPlan
            onMoreClick={handleMoreClick}
            isPlanDetailsAvailable={isPlanDetailsAvailable}
            onChangePlanClick={handleChangePlanClick}
          />
        )}
        {withFeatures && (
          <Features />
        )}
        <Footer
          tKey={tKey}
          onCancelSubscriptionClick={handleCancelSubscriptionClick}
          onChangePlanClick={handleChangePlanClick}
          onReturnToAppClick={handleReturnToAppClick}
        />
      </div>
      <ErrorPopup
        visible={!!errorMessage}
        tKey={'manage.terminate_subscription.error_modal'}
        buttonTitle='OK'
        onClose={handleCloseErrorClick}
        onSubmit={handleCloseErrorClick}
      />
      <InfoModal
        visible={infoModalOpen}
        onClose={handleCloseInfoModal}
        onChange={handleChangePlanClick}
        onCancel={handleCancelSubscriptionClick}
        onPause={handlePauseSubscriptionClick}
      />
    </>
  );
};
