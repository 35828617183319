import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from '../constants/routes';

import { useManageNavigation } from './use-manage-navigate';

export const useCheckIsPageAvailable = () => {
  const navigateTo = useNavigate()

  const { isPageAvailable } = useManageNavigation();

  useEffect(() => {
    if (!isPageAvailable(window.location.pathname)) {
      navigateTo(
        {
          pathname: ROUTES.MAIN,
          search: window.location.search
        },
        {
          replace: true
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
