import {
  getAuth,
  updateEmail,
} from "firebase/auth";

import DeviceProps from '@magnus/react-native-device-props';

import { request as requestBillingApi } from '@web-solutions/module-billing/api/network';

//@ts-ignore
import { authFirebase } from 'src/api/auth';

const billingApi = requestBillingApi(process.env.REACT_APP_API_BILLING_URL);

const authDeviceId = (idfm: string) =>
  billingApi.post('/auth/device-id', { device_id: idfm, });

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm => authDeviceId(idfm))

export const updateUserEmail = (email: string) =>
  DeviceProps.getIDFM()
    .then(authFirebase)
    .then(() => updateEmail(getAuth().currentUser!, email));