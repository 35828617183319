import type { FC } from 'react';
import i18next from 'i18next';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { useRemoteConfig } from '@web-solutions/base-app/hooks/use-remote-config';

import { DefaultProgress } from './components/default';
import { SingleWordProgress } from './components/single-word';

import { useGetActualSteps } from './hooks/use-get-actual-steps';

import classes from './style.module.scss';

export type ProgressSteps = "order" | "offer" | 'guide' | 'account';

interface ProgressProps {
  currentStep?: ProgressSteps;
  stepsText?: Record<ProgressSteps, string>,
  className?: string
}

const getStepStatus = (index: number, currentIndex: number) => {
  if (index > currentIndex) {
    return 'next';
  }
  if (index === currentIndex) {
    return 'current';
  }
  return 'prev';
};

const tKey = 'subscription.purchase_progress';

export const Progress: FC<ProgressProps> = ({ currentStep = 'offer', stepsText, className }) => {
  const { afterPurchaseFlow, purchaseProgressType = 'default' } = useRemoteConfig();
  const actualSteps = useGetActualSteps(afterPurchaseFlow);
  const defaultStepsText = t(`${tKey}.${purchaseProgressType}`, { returnObjects: true }) as any;

  const steps = stepsText || defaultStepsText;

  const isAllSteps = actualSteps.length === 4;
  const isDeLng = i18next.language === 'de';
  const currentStepIndex = actualSteps.findIndex((item) => item === currentStep)

  return (
    <div className={classNames(classes.progress, { [classes.narrow]: purchaseProgressType === 'single_word' }, className)}>
      {
        actualSteps.map((item, index) => {
          const stepText = steps[item as ProgressSteps];
          return purchaseProgressType === 'single_word' ?
            <SingleWordProgress
              key={item}
              text={stepText}
              status={getStepStatus(index, currentStepIndex)}
            />
            :
            <DefaultProgress
              key={item}
              text={stepText}
              addGap={isAllSteps && !isDeLng}
              status={getStepStatus(index, currentStepIndex)}
            />
        })
      }
    </div >
  )
}
