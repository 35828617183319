import classes from '../../style.module.scss';

const APP_NAME = process.env.REACT_APP_TITLE;

interface Props {
  text: React.ReactNode,
  email: string,
  subjectText: string
}

export const ContactUs: React.FC<Props> = ({ text, email, subjectText }) => (
  <>
    {text}
    <a
      href={`mailto:${email}?subject=${encodeURIComponent(`[${APP_NAME}] ${subjectText}`)}`}
      className={classes.link}
    >
      {` ${email}`}.
    </a>
  </>
)