import { createAsyncThunk } from '@reduxjs/toolkit';

import Attribution from '@web-solutions/module-attribution';
import Analytics from '@web-solutions/module-analytics';

import { init as initAccountManagment } from '@web-solutions/account-management/store/actions'

import { applyTheme } from '@web-solutions/core/utils/theme'

import { init as initRemoteConfig } from 'core/store/remote-config/actions';

import { INITIAL_CONFIG, } from 'src/constants/remote-config';

import { init as initRouting } from '../routing/actions';

import { getMagnusToken, validateRemoteConfig } from './utils';

import { AppState, appSlice } from '.';

export const { setAppLink, setLoaded, setPending } = appSlice.actions;

/**
 * Initialization RemoteConfig only
 */

export const loadRemoteConfig = createAsyncThunk(
  `${appSlice.name}/load_rc`,
  async (_, { dispatch }) => {
    try {
      await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG, { validate: validateRemoteConfig }));
    } catch (error) {
      console.log('[ERROR INIT REMOTE CONFIG]', error);
      throw error;
    } finally {
      dispatch(setLoaded(true));
    }
  });

export const initTheme = createAsyncThunk(
  `${appSlice.name}/initTheme`,
  async (_, { getState }) => {
    const state = getState() as any;
    const theme = state?.remoteConfig?.theme;
    if (theme) {
      applyTheme(theme)
    }
  }
);


export const load = createAsyncThunk(
  `${appSlice.name}/load`,
  async (_, { dispatch, getState }) => {
    try {
      await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG, { validate: validateRemoteConfig }));
      await dispatch(initTheme());
      await dispatch(initAccountManagment());
      await dispatch(initRouting());
      await dispatch(initAppLink());
    } catch (error) {
      console.log('[ERROR INIT APP]', error);
      return error;
    } finally {
      dispatch(setLoaded(true));
    }
  });

export const initAppLink = createAsyncThunk(
  `${appSlice.name}/initAppLink`,
  async (_, { dispatch }) => {
    const appLink = await Attribution.getLinkToApp();
    dispatch(setAppLink(appLink));
  }
);

export const downloadApp = createAsyncThunk(
  `${appSlice.name}/downloadApp`,
  async (_, { getState }) => {
    const state = getState();
    const { app: { appLink } } = state as { app: AppState };
    Analytics.trackEvent('install_app', 'click');
    window.location.href = appLink;
  }
);
