import UAParser from 'ua-parser-js';
import queryString from 'query-string';

import Analytics from '@web-solutions/module-analytics';

export enum REDIRECT_TO_BROWSER {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ON_CLICK = 'onclick',
}

type Params = { mode: REDIRECT_TO_BROWSER, link?: string, withIdfm?: boolean }

export function doRedirectToBrowser({ mode, link, withIdfm }: Params) {
  const r = new UAParser().getResult();
  if (['Facebook', 'Instagram', 'TikTok', 'Snapchat'].includes(r?.browser?.name || '')) {
    const q = queryString.parseUrl(link || window.location.href);
    link = queryString.stringifyUrl({
      url: q.url,
      query: {
        ...q.query,
        ...(withIdfm ? {} : { idfm: undefined, suggested_idfm: q.query.idfm }),
        bfc: q.query.fbclid ? btoa(String(q.query.fbclid)) : undefined,
      }
    });

    if (r?.os?.name === 'Android') {
      Analytics.trackEvent('redirect', 'to_browser', { mode });
      window.location.href = `intent:${link}#Intent;end`;
    } else if (r?.os?.name === 'iOS') {
      Analytics.trackEvent('redirect', 'to_browser', { mode });
      const l = 'x-safari-' + link;
      if (mode === REDIRECT_TO_BROWSER.ENABLED) {
        window.location.href = l;
      } else {
        window.open(l);
      }
    }
  }
}
