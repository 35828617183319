import { PaymentSystem } from '@web-solutions/react-billing';

import type { ProductConfig } from '@web-solutions/core/constants/remote-config';

import type { ProductAttachment } from '@web-solutions/core/interfaces/products';

export function getProductsIds(products: ProductConfig[]): string[] {
  return products.map((p) => p.id).filter(i => !!i) as string[];
}

type Ids = 'recurlyId' | 'solidgateId' | 'paddleId' | undefined

type IdsMap = {
  [key in PaymentSystem]: Ids;
};

const IDS_MAP: IdsMap = {
  [PaymentSystem.RECURLY]: 'recurlyId',
  [PaymentSystem.SOLIDGATE]: 'solidgateId',
  [PaymentSystem.PADDLE]: 'paddleId',
  [PaymentSystem.STRIPE]: undefined,
  [PaymentSystem.PAYPAL]: undefined,
  [PaymentSystem.TRUEGATE]: undefined,
}

interface PrepareProductsIdsParams {
  paymentSystem: PaymentSystem,
  products: ProductConfig[],
}

export function prepareProductsIds({ products, paymentSystem }: PrepareProductsIdsParams): ProductConfig[] {
  return products.map(p => ({
    ...p,
    id: p[IDS_MAP[paymentSystem] || 'id'] as string || p.id,
  }));
}

interface PrepareProductsParams extends PrepareProductsIdsParams {
  attachment: ProductAttachment
}

export function prepareProducts({ products = [], paymentSystem, attachment }: PrepareProductsParams): ProductConfig[] {
  return (
    products.length
      ?
      prepareProductsIds({ products, paymentSystem }).map(p => ({
        ...p,
        attachment
      }))
      :
      []
  );
}
