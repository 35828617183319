import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  type LString,
  type RemoteConfig as CoreRemoteConfig,
  type Nullable,
  BaseOfferProductTypes,
} from '@web-solutions/core/constants/remote-config';
import { InterButtonConfig } from '@web-solutions/core/payment/constants';
import { type CoreSummarySection } from '@web-solutions/core/summary/types';
import type { DefaultUpsaleOfferType, BaseUpsalesRemoteContentParams } from '@web-solutions/core/interfaces/upsaleOffers';
import type { CreateAccountFlowStep } from '@web-solutions/account-management/create-account/types';

import type { EmailViewMode } from '@web-solutions/core/containers/email';
import { Theme } from '@web-solutions/core/interfaces/theme';

import type { EmailPageContent } from 'src/interfaces/quiz/email';

import type { PlanDescriptionMode } from 'src/screens/subscription/sections/plan-description/types'

import { AfterPurchaseRoutes, HabioFlowStep } from './screens';

export enum SummarySection {
  ROUTINE_BEFORE_AFTER = 'routine_before_after',
  ROUTINE_DESCRIPTION = 'routine_description',
  ROUTINE_SYMPTOMS = 'routine_symptoms',
  BEFORE_AFTER = 'before_after',
  BEFORE_AFTER_BY_GENDER = 'before_after_by_gender',
  TEXT_HEADER = 'text_header',
  IMAGE_HEADER = 'image_header',
  CHART = 'chart',
  MONEY_BACK = 'moneyback',
  FAQ = 'faq',
  FAQ_UPDATED = 'faq_updated',
  FOCUS_FAQ = 'focus_faq',
  WILL_HELP_YOU_BIG_IMAGE = 'will_help_big_image',
  FOCUS_WILL_HELP_YOU_BIG_IMAGE = 'focus_will_help_big_image',
  FOCUS_WILL_HELP_YOU_BIG_IMAGE_PRESUM = 'focus_will_help_you_big_image_presum',
  WILL_HELP_YOU_ONE_COLOR = 'will_help_you_one_color',
  PERSONAL_SUMMARY = 'personal_summary',
  FEEDBACK_LIST = 'feedback_list',
  FOCUS_FEEDBACK_LIST = 'focus_feedback_list',
  DOWNLOADED = 'downloaded',
  ELIMINATE_PROCRASTINATION = 'eliminate_procrastination',
  UNLOCK_PRODUCTIVITY = 'unlock_productivity',
  FOCUS_UNLOCK_PRODUCTIVITY = 'focus_unlock_productivity',
  PROCRASTINATION_SUMMARY = 'procrastination_summary',
  FOCUS_PROCRASTINATION_SUMMARY = 'focus_procrastination_summary',
  DISCOUNT_TIMER = 'discount_timer',
  PROCRASTINATION_PERSONAL_PLAN = 'procrastination_personal_plan',
  ADHD_PERSONAL_PLAN = 'adhd_personal_plan',
  USERS_PERCENT = 'users_percent',
  WHAT_INCLUDED = 'what_included',
  ADHD_STATISTICS = 'adhd_statistics',
  PROCRASTINATION_STATISTICS = 'procrastination_statistics',
  NLM_STUDY = 'nlm_study',
  PROCRASTINATION_FEEDBACK_LIST = 'procrastination_feedback_list',
  ADHD_FEEDBACK_LIST = 'adhd_feedback_list',
  USERS_JOINED = 'users_joined',
  DETOX_UNLOCK_PRODUCTIVITY = 'detox_unlock_productivity',
  BREAK_FREE_DOPAMINE = 'break_free_dopamine',
  DETOX_PROCRASTINATION_SUMMARY = 'detox_procrastination_summary',
  DETOX_WILL_HELP_YOU_BIG_IMAGE = 'detox_will_help_big_image',
  DETOX_FEEDBACK_LIST = 'detox_feedback_list',
  USERS_STATS = 'users_stats',
  DETOX_USERS_STATS = 'detox_users_stats',
  BURNOUT_USERS_STATS = 'burnout_users_stats',
  FEATURED_IN = 'featured_in',
  ADHD_BREAKDOWN = 'adhd_breakdown',
  ADHD_TYPE = 'adhd_type',
  OVERALL_MOOD = 'overall_mood',
  ADHD_SYMPTOM_LEVEL = 'adhd_symptom_level',
  PROCRASTINATION_SYMPTOM_LEVEL = 'procrastination_symptom_level',
  DETOX_SYMPTOM_LEVEL = 'detox_symptom_level',
  BURNOUT_SYMPTOM_LEVEL = 'burnout_symptom_level',
  PROCRASTINATION_TYPE = 'procrastination_type',
  DETOX_TYPE = 'detox_type',
  BURNOUT_TYPE = 'burnout_type',
  BUILD_LIFESTYLE = 'build_lifestyle',
  OVST_PROCRASTINATION_SUMMARY = 'ovst_procrastination_summary',
  OVST_WILL_HELP_YOU_BIG_IMAGE = 'ovst_will_help_big_image',

  FACE_READING_OVERVIEW = 'face_reading_overview',
  FACE_READING_OVERVIEW_SHORT = 'face_reading_overview_short'
};

export enum OfferProductId {
  HABITS_WORKBOOK = 'habits_workbook',
  ADHD_WORKBOOK = 'adhd_workbook',
  HABITS_WORKBOOK_ADHD_WORKBOOK = 'adhd_workbook+habits_workbook',
}

export type OfferProductTypes = BaseOfferProductTypes<OfferProductId>

export type AfterPurchaseFlow = AfterPurchaseRoutes

export type FinallOfferProduct = Omit<OfferProductTypes, | 'id'>;

export type UpsaleOffer = DefaultUpsaleOfferType<Omit<OfferProductTypes, 'id'>> & BaseUpsalesRemoteContentParams

type SuccessPageType = 'extended_email' | 'extended_email_v2' | 'extended_email_cards'

export interface RemoteConfig extends CoreRemoteConfig<HabioFlowStep> {
  withContactUsBtnInCheckEmail: boolean,
  summaryHeader: {
    title: LString,
    subtitle: LString,
  },
  showMailCheckbox: boolean,
  showSuccessPopupAfterPurchase: boolean,
  emailPageTitle: LString,
  emailPageViewMode: EmailViewMode,
  emailPageContent?: EmailPageContent,
  landingStack: Array<SummarySection | CoreSummarySection>,
  landingButtons: Array<InterButtonConfig<SummarySection>>,
  presummaryStack: Array<SummarySection | 'reserved_price'>,
  presummaryButtons: Array<InterButtonConfig<SummarySection>>,
  hideStepsAmountOnProgress: boolean,
  beforeAfterBlock: {
    imageType: 'man' | 'closet' | 'room' | 'life',
    title: LString,
    text: LString,
  },
  theme: Theme,
  adhdWorkbook: UpsaleOffer,
  habitsWorkbook: UpsaleOffer,
  ultimateGuide: UpsaleOffer,
  magicDurationLoad: number,
  magicStages: LString[],
  autocompleteEmail: boolean,
  focusEmail: boolean,
  quizWelcomePage: {
    uptitle: LString,
    title: LString,
    subtitle: LString,
    button: LString,
  },
  afterPurchaseFlow: AfterPurchaseFlow,
  purchaseProgressType: 'default' | 'single_word',
  isProcrastination: boolean,
  upsaleWorkbookFinalOffer: {
    enabled: boolean,
    button: LString,
    skipButton: LString,
    product: FinallOfferProduct,
  },
  upsaleWorkbook: {
    title: LString,
    text: LString,
    button: LString,
    activeOfferId: OfferProductId | null,
    successModalEnabled: boolean,
    autoRedirectEnabled: boolean,
    products: OfferProductTypes[],
  },
  cameraInitTimeout: number,
  isFaceReadingSkipAvailable: boolean,
  isFaceReadingUploadPhotoAvailable: boolean,
  isFaceReadingBackAvailable: boolean,
  isFaceReadingApproveAvailable: boolean,
  planDescription: {
    mode: PlanDescriptionMode,
    isPlanDescriptionVisible: boolean,
  }
  faceReadingWelcomeType: 'image' | 'static' | 'video',
  faceReadingWelcome?: {
    title?: {
      mode?: 'procrastination' | 'adhd' | 'dopamine'
      value?: LString
    }
  }
  quizSurvey: {
    link: string,
    enableClose: boolean,
  },
  createAccountFlow: Exclude<CreateAccountFlowStep, 'google'>[],
  successPageType: SuccessPageType,
  redirectTerminateToManage: boolean,
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,
  withContactUsBtnInCheckEmail: false,
  hideStepsAmountOnProgress: false,
  summaryHeader: {
    title: '',
    subtitle: '',
  },
  emailPageTitle: { en: '' },
  emailPageViewMode: 'default',
  emailPageContent: {
    mode: 'default',
    showJoinedUsers: true,
    showStepsLeft: true,
  },
  theme: {
    mode: 'monochrome',
  },
  showMailCheckbox: false,
  showSuccessPopupAfterPurchase: true,
  beforeAfterBlock: {
    text: '',
    title: '',
    imageType: 'room',
  },

  landingStack: [],
  landingButtons: [],

  presummaryStack: [],
  presummaryButtons: [],

  magicDurationLoad: 8000,
  magicStages: [],
  flow: [],
  autocompleteEmail: false,
  focusEmail: false,
  quizWelcomePage: {
    uptitle: '',
    title: '',
    subtitle: '',
    button: '',
  },
  isProcrastination: false,
  isOneClickFootnote: true,
  afterPurchaseFlow: ['GUIDES'],
  purchaseProgressType: 'default',
  upsaleWorkbookFinalOffer: {
    enabled: true,
    button: '',
    skipButton: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      currency: 'USD',
      price: 1,
    },
  },
  adhdWorkbook: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
  habitsWorkbook: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
  ultimateGuide: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '$49.99',
      activePrice: '$19.99',
      discount: '60%',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
  upsaleWorkbook: {
    title: '',
    text: '',
    button: '',
    activeOfferId: OfferProductId.ADHD_WORKBOOK,
    successModalEnabled: true,
    autoRedirectEnabled: false,
    products: [
      {
        id: OfferProductId.HABITS_WORKBOOK,
        title: '',
        subTitle: '',
        notActivePrice: '',
        activePrice: '',
        discount: '',
        currency: 'USD',
        price: 1,
      },
      {
        id: OfferProductId.ADHD_WORKBOOK,
        title: '',
        subTitle: '',
        notActivePrice: '',
        activePrice: '',
        discount: '',
        currency: 'USD',
        price: 1,
      },
      {
        id: OfferProductId.HABITS_WORKBOOK_ADHD_WORKBOOK,
        title: '',
        subTitle: '',
        notActivePrice: '',
        activePrice: '',
        discount: '',
        currency: 'USD',
        price: 1,
      },
    ],
  },
  cameraInitTimeout: 4000,
  isFaceReadingSkipAvailable: false,
  isFaceReadingUploadPhotoAvailable: false,
  isFaceReadingBackAvailable: true,
  isFaceReadingApproveAvailable: false,
  planDescription: {
    mode: 'detox',
    isPlanDescriptionVisible: true,
  },
  faceReadingWelcomeType: 'static',
  faceReadingWelcome: {},
  quizSurvey: {
    link: '',
    enableClose: false,
  },
  createAccountFlow: ['email', 'apple_id'],
  successPageType: 'extended_email',
  redirectTerminateToManage: false,
};

export type FullConfig = Nullable<Partial<RemoteConfig>>;
