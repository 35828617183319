import type { FC } from 'react';

import { t } from '@web-solutions/module-localization';

import { SocialButtonBase } from '../base-button'

import { baseTKey } from '../constants';

import type { SocialButtonProps } from '../types';

import { GoogleIcon } from './icons'

export const GoogleButton: FC<SocialButtonProps> = (props) => (
  <SocialButtonBase icon={<GoogleIcon />} {...props}>
    {t(`${baseTKey}.google`)}
  </SocialButtonBase>
);