import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentSystem } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';

import {
  initOrders,
  handleErrorPurchase,
  switchToReservePaymentSystem,
} from '@web-solutions/core/store/billing/actions';
import { type ProductDetails, selectNoFundsOfferEndDate, } from '@web-solutions/core/store/billing/selectors';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { SOLIDGATE_ERROR_CODES } from '@web-solutions/core/constants/errors';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import type { PaymentError } from '@web-solutions/core/interfaces/errors';

type Params = {
  activeProduct: ProductDetails,
  setErrorCode: React.Dispatch<React.SetStateAction<string>>
  setIsOpenErrorPopup: React.Dispatch<React.SetStateAction<boolean>>
  onErrorTrigger?: (error: any) => void,
  onError?: (error: any) => void,
}

export const useGetCardErrorHandler = ({ activeProduct, setErrorCode, setIsOpenErrorPopup, onErrorTrigger, onError }: Params) => {
  const dispatch = useDispatch();

  const {
    noFundsOffer,
    errorPopup,
  } = useRemoteConfig();

  const noFundsOfferEndDate = useSelector(selectNoFundsOfferEndDate);

  const onCardError = useCallback((error: PaymentError) => {
    const isInsufficientFundsErrorCode = error?.code === SOLIDGATE_ERROR_CODES.INSUFFICIENT_FUNDS;
    const isSONoFundsTriggered = isInsufficientFundsErrorCode && noFundsOffer.enabled && !noFundsOfferEndDate && error?.entity === 'form'

    if (!activeProduct?.isOneTimePurchase && isSONoFundsTriggered) {
      onErrorTrigger && onErrorTrigger(error);

      dispatch(handleErrorPurchase(error, { noToast: true }));

      if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
        dispatch(initOrders());
      }

      onError && onError(error);
      return
    }

    let noToast;

    setErrorCode && setErrorCode(error?.code)

    if (!activeProduct?.isOneTimePurchase && errorPopup?.enabled) {
      Analytics.trackEvent('error_popup', EVENT_ACTION.OPEN, { message: error?.message, code: error?.code, method: error?.method, paymentSystem: error?.paymentSystem });

      onErrorTrigger && onErrorTrigger(error);

      setIsOpenErrorPopup(true);

      if (!isInsufficientFundsErrorCode) {
        dispatch(switchToReservePaymentSystem());
      }

      noToast = true;
    }

    dispatch(handleErrorPurchase(error, { noToast }));

    if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
      dispatch(initOrders());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeProduct?.isOneTimePurchase,
    errorPopup.enabled,
    noFundsOffer.enabled,
    noFundsOfferEndDate,
    dispatch,
    onError
  ])

  return { onCardError }
}