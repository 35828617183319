import React, { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { Title, Button, Text, Modal } from '@web-solutions/core/ui-elements';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t, TM } from '../../../localization';

import { goAppLink, useSliceDispatch } from '../../slice';

import { useManageContext } from '../../hooks/use-manage-context';

import classes from './style.module.scss';

interface SuccessModalProps {
  isOpen: boolean,
  category: string,
  tKey: string,
  title?: string,
  onClose?: () => void,
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  category,
  tKey,
  title,
  onClose,
}) => {
  const dispatch = useSliceDispatch();
  const { successModalContent } = useManageContext();

  const handleClose = () => {
    Analytics.trackEvent(category, EVENT_ACTION.CLOSE);
    if (onClose) {
      onClose()
    } else {
      dispatch(goAppLink());
    }
  }

  useEffect(() => {
    if (isOpen) {
      Analytics.trackEvent(category, EVENT_ACTION.OPEN);
    }
  }, [category, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <div className={classes.content}>
        {successModalContent}
        <Title level="h2" className={classes.title}>
          <TM k={title} defaultKey={`${tKey}.title`} />
        </Title>
        <Text className={classes.text}>{t(`${tKey}.content`)}</Text>
      </div>
      <Button
        onClick={handleClose}
        iconRight={false}
        className={classes.button}
        title={t(`${tKey}.button`)}
        titleClassName={classes.button__title}
      />
    </Modal>
  );
};
