import { useEffect, FC } from 'react'

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { WithoutLogo } from './without_logo'
import { Default } from './default'

export const CancelSuccessPage: FC = () => {
  const { mngSubCancelSuccessPage } = useRemoteConfig();

  useEffect(() => {
    Analytics.trackEvent('manage_cancel_success', EVENT_ACTION.OPEN);
  }, []);

  if (mngSubCancelSuccessPage.mode === 'without_logo') {
    return <WithoutLogo />
  }

  return <Default />
};
