import { Currency, PaymentSystem } from "@web-solutions/react-billing/constants";

export enum SubscriptionState {
  ACTIVE = 'active',
  PAUSED = 'paused',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELED = 'cancelled',
  EXPIRED = 'expired',
  PAUSED = 'paused',
} 

export interface ManageSubscription {
  id: number,
  amount: number,
  currency: Currency,
  product: string,
  active: boolean,
  status: SubscriptionStatus,
  state: SubscriptionState,
  payment_service: PaymentSystem,
  email: string,
  created_at: string,
  external_id: string,
  expire_at: string,
  trial_end_at: string,
  canceled_at: string,
  sig: string,
  next_charge_at: string,
}