import { T, t, tm } from "@web-solutions/module-localization";

import { ProductDetails } from "core/store/billing/selectors";
import { Text, Title } from "core/ui-elements";

import classes from './style.module.scss';

interface SinglePlanProps {
  product: ProductDetails,
}

export const SinglePlan: React.FC<SinglePlanProps> = ({ product }) => {

  const {
    amount,
    period,
    trialDays,
    introExpireDate,
    textLineThrough,
    currency,
    trial_price_amount,
  } = product;

  return <div className={classes.wrap}>
    <Title className={classes.title} level="h2">{t('core.single_plan.title')}</Title>
    <div className={classes.price_wrap}>
      <div className={classes.text}>{t('core.single_plan.trial_title', { days: trialDays })}</div>
      <div className={classes.price}>
        {textLineThrough && <span className={classes.lineThrough}>{tm(textLineThrough, '', { ...product })}</span>}
        <span>{trial_price_amount} {currency}</span>
      </div>
    </div>
    <Text className={classes.text_footnote}>
      <T k="core.single_plan.text" tOptions={{ daysCount: trialDays }} />
    </Text>
    <Title className={classes.title} level="h2">{t('core.single_plan.title_secondary')}</Title>
    <div className={classes.price_wrap}>
      <div className={classes.text}>{t(`core.plans.items_titles.${period}`)}</div>
      <div className={classes.week_price}>
        <div>{amount} {currency}</div>
        {period !== 'P1W' && <div className={classes.week}>
          {t('core.single_plan.week_price', { ...product })}
        </div>
        }
      </div>
    </div>
    <Text className={classes.text_footnote}>{t('core.single_plan.text_secondary', { date: introExpireDate })}</Text>
  </div>
}