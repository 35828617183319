import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';

import { Text, Title, Button } from '@web-solutions/core/ui-elements';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t, tm, } from '../../../../localization';
import { SubscriptionStatus, SubscriptionState } from '../../../types';

import { ReactComponent as ArrowBackIcon } from '../../../components/icons/arrow-back.svg';

import { useManageContext } from '../../../hooks/use-manage-context';
import { selectProduct, selectSubscription } from '../../../slice';

import classes from './style.module.scss';

const tKey = 'manage.current_plan';

interface CurrentPlanProps {
  onCancelClick?: () => void;
  onChangePlanClick: () => void;
  isPlanDetailsAvailable?: boolean;
  isSubUpdating?: boolean;
  withoutBgImage?: boolean;
}

export const CurrentPlan: React.FC<CurrentPlanProps> = ({
  onCancelClick,
  onChangePlanClick,
  isPlanDetailsAvailable,
  isSubUpdating,
  withoutBgImage
}) => {

  const subscription = useSelector(selectSubscription);
  const product = useSelector(selectProduct);
  const { currentPlanContent } = useManageContext();

  const { mngSubMainPage: config } = useRemoteConfig();

  const isEditBtnEnabled = config.editBtnEnabled;
  const isCancelBtnEnabled = isPlanDetailsAvailable && !isSubUpdating && Boolean(config?.updatingDelay)

  const [expanded, setExpanded] = useState(!config?.planDetailsEnabled);

  if (!subscription || !product) {
    return null;
  }

  const handleMoreClick = () => {
    Analytics.trackEvent('manage_main_more_less', EVENT_ACTION.CLICK);
    setExpanded(!expanded);
  }

  return (
    <div className={classNames(classes.wrap, { [classes.expanded]: expanded, [classes.bgWithoutImg]: withoutBgImage })}>
      <div className={classes.headerWrapper}>
        <Title
          level='h2'
          className={classes.title}
        >
          {t(`${tKey}.title`)}
        </Title>
        {isSubUpdating &&
          <div className={classes.updatingBadge}>
            {t(`${tKey}.updating_badge`)}
          </div>
        }
      </div>
      {isPlanDetailsAvailable && <>
        <button onClick={handleMoreClick} className={classes.more_button}>
          <ArrowBackIcon />
        </button>
        {currentPlanContent}
      </>}
      <div className={classes.content_container}>
        <div className={classes.row}>
          <Text className={classes.left}>{t(`${tKey}.status`)}</Text>
          <Text
            className={classNames(classes.right, {
              [classes.canceled]: subscription.status === SubscriptionStatus.CANCELED || subscription.status === SubscriptionStatus.EXPIRED
            })}>
            {subscription.state === SubscriptionState.PAUSED ? t(`manage.status.paused`) : t(`manage.status.${subscription.status}`)}
          </Text>
        </div>
        {product.period && (
          <div className={classes.row}>
            <Text className={classes.left}>{t(`${tKey}.period`)}</Text>
            <Text className={classes.right}>{t(`manage.period.${product.period}`)}</Text>
          </div>
        )}
        {expanded && <>
          <div className={classNames(classes.row, { [classes.bottom_row]: isEditBtnEnabled })}>
            <Text className={classes.left}>{t(`${tKey}.price`)}</Text>
            <Text className={classes.right}>{tm(config.currentPlanPrice, '', { ...product })}</Text>
          </div>
          {subscription.status === SubscriptionStatus.CANCELED
            ? (
              <div className={classes.row}>
                <Text className={classes.left}>{t(`${tKey}.expire`)}</Text>
                <Text className={classes.right}>{dayjs(subscription.expire_at).format('LL')}</Text>
              </div>
            ) :
            subscription.status === SubscriptionStatus.EXPIRED
              ? (
                <div className={classes.row}>
                  <Text className={classes.left}>{t(`${tKey}.expired`)}</Text>
                  <Text className={classes.right}>{dayjs(subscription.expire_at).format('LL')}</Text>
                </div>
              )
              : null}
          {isCancelBtnEnabled && (
            <button
              onClick={onCancelClick}
              className={classNames(classes.cancel_button, {
                [classes.cancel_button_disabled]: !onCancelClick,
                [classes.cancel_button_none]: isEditBtnEnabled,
              })}>
              {t(`${tKey}.cancel`)}
            </button>
          )
          }
        </>}
      </div>
      {!isPlanDetailsAvailable && currentPlanContent}
      {isEditBtnEnabled &&
        <div className={classes.change_plan_btn_wrap}>
          <Button
            onClick={onChangePlanClick}
            className={classes.change_plan_btn}
            titleClassName={classes.change_plan_btn_title}
            title={t(`${tKey}.change_plan_btn`)}
          />
          {isCancelBtnEnabled && (
            <button
              onClick={onCancelClick}
              className={classNames(classes.cancel_button, {
                [classes.cancel_button_bottom]: isEditBtnEnabled,
              })}>
              {t(`${tKey}.cancel`)}
            </button>
          )
          }
        </div>
      }
    </div>
  );
};
