
import queryString from 'query-string';
import { createBrowserHistory } from 'history';

import DeviceProps from '@magnus/react-native-device-props';
import EvTruckAPI from '@magnus/react-native-evtruck/src/api';

import Analytics from '@web-solutions/module-analytics';


export function parseUrlParams() {
  const p = queryString.parse(window.location.search);
  const pType = window.location.pathname.split('/')[1];
  if (p.idfm) {
    try {
      window.localStorage.setItem('@RNMV/IDFM', String(p.idfm));
    }
    catch (ex) { console.warn(ex); }
  } else if (p.suggested_idfm) {
    try {
      const idfm = window.localStorage.getItem('@RNMV/IDFM');
      if (!idfm || idfm === p.suggested_idfm) {
        window.localStorage.setItem('@RNMV/IDFM', String(p.suggested_idfm));
        p.idfm = p.suggested_idfm;
        setTimeout(() => {
          Analytics.trackEvent('redirect_to_browser', 'success');
        }, 500);
      } else {
        p.idfm = idfm;
        const suggested_idfm = p.suggested_idfm;
        setTimeout(() => {
          DeviceProps.fetch()
            .then((deviceProps) => {
              EvTruckAPI.request('/collector/event', {
                "platform": "web",
                "idfm": suggested_idfm,
                "app_version": deviceProps?.app_version,
                "os_version": deviceProps?.os_version,
                "events": [
                  {
                    "event_name": "redirect_to_browser_conflict",
                    "event_time_ms": Date.now(),
                    "event_params": {
                      "idfm": idfm
                    }
                  }
                ]
              });
            });
        }, 500);
      }
      delete p.suggested_idfm;
      createBrowserHistory().replace(window.location.pathname + '?' + queryString.stringify(p));
    }
    catch (ex) { console.warn(ex); }
  } else if (pType !== 'terminate') {
    return DeviceProps.getIDFM()
      .then(idfm => {
        p.idfm = idfm;
        createBrowserHistory().replace(window.location.pathname + '?' + queryString.stringify(p));
        return p;
      });
  }
  return Promise.resolve(p);
}

export function stringifyUrlParams(params: Record<string, any>) {
  const p = queryString.parse(window.location.search);
  createBrowserHistory().replace(window.location.pathname + '?' + queryString.stringify({ ...p, ...params }));
}

export const urlToFile = async (url: string, filename: string, mimeType: string) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

export const isManage = () => {
  return window.location.pathname.split('/')[1] === 'manage'
}

export const isTerminate = () => {
  return window.location.pathname.split('/')[1] === 'terminate'
}
