
import { tm } from '@web-solutions/module-localization';
import type { PriceTypes } from '@web-solutions/core/constants/remote-config';
import type { ProductDetails } from '@web-solutions/core/store/billing/selectors';

import { vatPriceTypes, vatTypes } from '../prices';

const priceTypes = {
  today: 'priceText',
  week: 'weekPrice',
  day: 'dayPrice',
} as const

interface Params {
  activeProduct: ProductDetails,
  totalPriceType: PriceTypes
}

export const getActualPrices = ({ activeProduct, totalPriceType }: Params) => {
  const {
    textLineThrough,
    isTrial,
    trialPrice,
  } = activeProduct;

  const priceText = activeProduct[priceTypes[totalPriceType]];

  const actualVatPrice = activeProduct[vatPriceTypes[totalPriceType]]
  const actualVat = activeProduct[vatTypes[totalPriceType]]

  const actualTrialVatPrice = activeProduct?.vatTrialPrice
  const actualTrialVat = activeProduct?.vatTrial

  const vatPrice = isTrial ? actualTrialVatPrice : actualVatPrice;

  const vat = isTrial ? actualTrialVat : actualVat;

  const descPrice = `${priceText}(${actualVatPrice} + ${actualVat} VAT)`

  const descPriceTrial = `${trialPrice}(${actualTrialVatPrice} + ${actualTrialVat} VAT)`

  return {
    descPrice,
    descPriceTrial,
    vatPrice,
    vat,
    price: priceText,
    fullPrice: tm(textLineThrough, '', { ...activeProduct }),
    actualPrice: isTrial ? trialPrice : priceText,
  }
}
